import React from 'react';
import clsx from 'clsx';

import RadioButton from '../RadioButton';

import './styles.scss';

export default function RadioGroup({
  checkedItem,
  className,
  group,
  onChange,
  onClick,
  options,
  variant,
}) {
  return (
    <fieldset className={clsx('radio-group', className, variant && `radio-group--${variant}`)}>
      {options.map((item) => (
        <RadioButton
          checked={checkedItem === item.value}
          key={`${item.label}-radio`}
          name={group}
          value={item.value}
          variant={variant}
          disabled={item.disabled}
          onChange={() => onChange && onChange(item.value)}
          onClick={(event) => onClick && onClick(event, item.value)}
        >
          {item.label}
        </RadioButton>
      ))}
    </fieldset>
  );
}
