import React, { useState } from 'react';
import groupBy from 'lodash/groupBy';

import BarChart from 'components/react/BarChart';
import Legend from 'components/react/Legend';
import PortalTooltip from 'components/react/PortalTooltip';
import ChartReadout from 'components/react/ChartReadout';
import SourceLabel from 'components/react/SourceLabel';
import ChartHeader from 'components/react/ChartHeader';
import ChartContainer from 'components/react/ChartContainer';

import { formatThousands, formatScaleSuffix } from '../../utils/formatters.js';

import './styles.scss';

export function groupAndMergeData(
  data,
  groupingKey,
  propKey,
  valueKey,
  driversTotalKey = 'Total Forest Loss By Driver',
  totalKey = 'All Forest Loss',
  unknownKey = 'Unknown'
) {
  const groupedData = groupBy(data, groupingKey);
  const years = Object.keys(groupedData);
  // Find latest year with drivers data
  const latestYear = years.reduce((result, year) => {
    if (
      groupedData[year].find((datum) => datum[propKey] === driversTotalKey && !!datum[valueKey])
    ) {
      return Math.max(result, year);
    } else {
      return result;
    }
  }, null);
  // Get largest driver for latest year
  const largestDriver = groupedData[latestYear].reduce((result, datum) => {
    if (
      datum[propKey] === driversTotalKey ||
      datum[propKey] === totalKey ||
      datum[propKey] === unknownKey
    ) {
      return result;
    }
    if (!result || result[valueKey] < datum[valueKey]) {
      result = datum;
    }
    return result;
  }, null);

  const mergedData = Object.keys(groupedData).map((groupKey) => {
    // Grouping the data converts the group keys to strings, so we pick
    // the original key from the first data item
    const originalGroupKey = groupedData[groupKey][0][groupingKey];
    const buffer = { [groupingKey]: originalGroupKey };
    groupedData[originalGroupKey].forEach((datum) => {
      if (datum[propKey] === driversTotalKey) {
        return;
      }
      const key =
        datum[propKey] === largestDriver[propKey]
          ? 'primary'
          : datum[propKey] === totalKey
          ? 'total'
          : datum[propKey] === unknownKey
          ? 'unknown'
          : 'other';
      if (!buffer[key] && datum[valueKey] === null) {
        return;
      }
      buffer[key] = (buffer[key] || 0) + datum[valueKey];
    });
    return buffer;
  });

  return { data: mergedData, largestDriver };
}

export default function LossContainer({ data, xDomain, source }) {
  const [activeX, setActiveX] = useState(2018);
  const [hovered, setHovered] = useState(false);

  const { data: mergedData, largestDriver } = groupAndMergeData(data, 'year', 'driver', 'area');

  const datum = mergedData.find((d) => d.year === activeX);

  const tooltipDatum = data
    .filter((d) => {
      if (
        d.year === activeX &&
        d.area != null &&
        d.driver !== 'All Forest Loss' &&
        d.driver !== 'Total Forest Loss By Driver' &&
        d.driver !== 'Other'
      ) {
        return d;
      }
    })
    .sort((a, b) => b.area - a.area);

  const tooltipData = {};
  const tooltipLabels = {};
  const tooltipSwatchKeys = {};
  const tooltipKeys = [];
  tooltipDatum.forEach((d) => {
    const key = d.driver;
    const value = d.area;
    tooltipData[key] = formatScaleSuffix(value);
    tooltipLabels[key] = key;
    tooltipSwatchKeys[key] =
      key === largestDriver.driver ? 'primary' : key === 'Unknown' ? 'unknown' : 'other';
    if (key !== 'Unknown') {
      tooltipKeys.push(key);
    }
  });

  tooltipKeys.push('Unknown');

  return (
    <div className="loss-container">
      {/* FIXME: Remove lowercasing */}
      <ChartHeader
        data={formatThousands(datum.total)}
        year={activeX}
        measure={__('country-use-drivers-unit').toLowerCase()}
      />
      <ChartContainer variant="barchart">
        <BarChart
          data={mergedData}
          xKey="year"
          xDomain={xDomain}
          yKeys={['primary', 'other', 'unknown']}
          activeX={activeX}
          hovered={hovered}
          onHover={(x, event) => {
            const active = event && (event.type === 'mouseenter' || event.type === 'touchstart');
            setActiveX(active ? x : 2018);
            setHovered(active);
          }}
        />
      </ChartContainer>
      <Legend
        items={[
          { id: 'primary', title: largestDriver.driver },
          { id: 'other', title: 'Other drivers' },
          { id: 'unknown', title: 'Unknown' },
        ]}
      />
      {source && <SourceLabel html={source} />}
      {hovered && (
        <PortalTooltip>
          <ChartReadout
            title={`Drivers of forest loss ${activeX}, ha`}
            data={tooltipData}
            keys={tooltipKeys}
            labels={tooltipLabels}
            swatchKeys={tooltipSwatchKeys}
            withSwatches
          />
        </PortalTooltip>
      )}
    </div>
  );
}
