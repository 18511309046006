import React, { useState } from 'react';

import LabelledDonutChart from 'components/react/LabelledDonutChart';
import SourceLabel from 'components/react/SourceLabel';
import ChartHeader from 'components/react/ChartHeader';
import ChartContainer from 'components/react/ChartContainer';

import { formatMillions } from '../../utils/formatters.js';

import './styles.scss';

const LABELS = { other: 'Other', forest: 'Forest' };

export default function LandUseContainer({ data, source }) {
  const [hoveredKey, setHoveredKey] = useState(null);

  const areaData = data.reduce((acc, datum) => {
    acc[datum.type] = datum.area;
    return acc;
  }, {});
  const forestPercentage = areaData['Total forest area'] / areaData['Total land area'];
  const donutData = {
    other: 1 - forestPercentage,
    forest: forestPercentage,
  };
  const totalsData = {
    other: areaData['Total land area'] - areaData['Total forest area'],
    forest: areaData['Total forest area'],
  };

  return (
    <div className="land-area-container">
      <ChartHeader
        data={formatMillions(hoveredKey ? totalsData[hoveredKey] : areaData['Total land area'], 1)}
        year="2019"
        measure="hectares"
      />
      <ChartContainer variant="donut">
        <LabelledDonutChart
          data={donutData}
          keys={['other', 'forest']}
          labels={LABELS}
          radius={104}
          thickness={52}
          activeKey={hoveredKey}
          hovered={!!hoveredKey}
          onHover={(key) => {
            setHoveredKey(key);
          }}
        />
      </ChartContainer>
      {source && <SourceLabel html={source} />}
    </div>
  );
}
