class TitleBarBehaviour {
  static id = 'title-bar';

  constructor(node) {
    if (!('IntersectionObserver' in window) || !node.dataset.subject) {
      return;
    }

    this.node = node;
    this.subjectNode = document.querySelector(node.dataset.subject);

    if (!this.subjectNode) {
      return;
    }

    const threshold = node.dataset.threshold || 0.5;

    const observer = new IntersectionObserver(
      (changes) => {
        // isIntersecting seems to be incorrect in some browser so just check our threshold
        // directly against the intersectionRatio
        if (!changes.length || changes[0].intersectionRatio >= threshold) {
          this.hide();
        } else {
          this.show();
        }
      },
      { threshold: threshold }
    );

    observer.observe(this.subjectNode);

    // TODO: Add prefixed event names
    this.node.addEventListener('transitionend', this.handleTransitionEnd);
  }

  show() {
    this.node.classList.remove('is-inert');
    this.node.classList.add('is-sticky');
  }

  hide() {
    this.node.classList.remove('is-sticky');
  }

  handleTransitionEnd = () => {
    if (!this.node.classList.contains('is-sticky')) {
      this.node.classList.add('is-inert');
    }
  };
}

export default TitleBarBehaviour;
