import React from 'react';
import clsx from 'clsx';

import './styles.scss';

// FIXME: The styles provided by this component should be moved into the respective child
// components themselves
export default function ChartContainer({ variant, children }) {
  return (
    <div className={clsx('chart-container', variant && `chart-container--${variant}`)}>
      {children}
    </div>
  );
}
