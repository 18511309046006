class HeaderBehaviour {
  static id = 'header';

  constructor(node) {
    this.node = node;
    this.subjectNode = document.querySelector(node.dataset.subject);
    this.mobileNode = document.querySelector(node.dataset.mobile);
    this.lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;
    window.addEventListener('scroll', this.handleScroll.bind(this), false);
  }

  handleScroll() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollTop > this.lastScrollTop) {
      this.hideNav();
    } else {
      this.showNav();
    }
    this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
  }

  hideNav() {
    this.node.classList.add('is-hidden');
    if (this.subjectNode) {
      this.subjectNode.classList.add('hidden-nav');
    }
    if (this.mobileNode) {
      this.mobileNode.classList.add('hidden-nav');
    }
  }

  showNav() {
    this.node.classList.remove('is-hidden');
    if (this.subjectNode) {
      this.subjectNode.classList.remove('hidden-nav');
    }
    if (this.mobileNode) {
      this.mobileNode.classList.remove('hidden-nav');
    }
  }
}

export default HeaderBehaviour;
