import React from 'react';

import './styles.scss';

import {
  GovernanceParentRow,
  GovernanceChildRow,
  GovernanceOverallRow,
  GovernanceHeader,
} from '../GovernanceRow';

export default function GovernanceScoresChart({ overall, data, header, statuses = {} }) {
  return (
    <table className="governance-chart">
      <GovernanceHeader title={header.title} labels={header.labels} binary={header.binary} />
      <GovernanceOverallRow
        title={overall.title}
        score={overall.score}
        status={statuses[overall.status]}
      />
      {data.map((datum) => {
        return (
          <GovernanceParentRow
            key={datum.title}
            title={datum.title}
            score={datum.score}
            status={statuses[datum.status]}
          >
            {datum.children.map((child) => (
              <GovernanceChildRow
                key={child.title}
                title={child.title}
                score={child.score}
                status={statuses[child.status]}
              />
            ))}
          </GovernanceParentRow>
        );
      })}
    </table>
  );
}
