export function openWindow(url, w = 550, h = 350) {
  const sw = window.screen.width * (window.devicePixelRatio || 1);
  const sh = window.screen.height * (window.devicePixelRatio || 1);
  const x = sw / 2 - w / 2;
  const y = sh / 4 - h / 2;

  window.open(
    url,
    'share',
    `height=${h},width=${w},top=${y},left=${x},toolbar=0,location=0,menubar=0,directories=0,scrollbars=0`
  );
}
