export default class MenuBehaviour {
  static id = 'menu';

  constructor(node) {
    this.node = node;
    this.triggerNode = node.querySelector('button[aria-expanded]');
    const navSelector = this.triggerNode.getAttribute('aria-controls');
    this.navNode = document.getElementById(navSelector);
    this.triggerNode.addEventListener('click', this.handleClickTrigger);

    if (this.navNode) {
      this.links = this.navNode.getElementsByClassName('mobile-nav__link');
    }
  }

  toggle(open) {
    if (open === undefined) {
      open = !!this.navNode.hidden;
    }

    this.triggerNode.setAttribute('aria-expanded', open ? 'true' : 'false');

    if (open) {
      this.navNode.removeAttribute('hidden');
      this.links.forEach((element) => element.removeAttribute('tabindex'));
    } else {
      this.navNode.setAttribute('hidden', true);
      this.links.forEach((element) => element.setAttribute('tabindex', '-1'));
    }

    if (open) {
      document.addEventListener('scroll', this.handleClickOutside, true);
      document.addEventListener('click', this.handleClickOutside, true);
    } else {
      document.removeEventListener('click', this.handleClickOutside, true);
    }
  }

  handleClickTrigger = () => {
    this.toggle();
  };

  handleClickOutside = (event) => {
    if (
      event.target === this.triggerNode ||
      event.target === this.dropdownNode ||
      this.triggerNode.contains(event.target) ||
      this.navNode.contains(event.target)
    ) {
      return;
    }

    this.toggle(false);
  };
}
