import React from 'react';
import './styles.scss';

export default function RadioButton({
  checked,
  children,
  name,
  value,
  onChange,
  onClick,
  variant,
  disabled,
}) {
  const id = `${name}-${value}`;

  return (
    <span className={`radio-button ${variant ? `radio-button--${variant}` : ''}`}>
      <input
        checked={checked}
        id={id}
        name={name}
        className="radio-button__input"
        type="radio"
        value={value}
        disabled={disabled}
        onChange={onChange}
      />
      <label htmlFor={id} className="radio-button__label" onClick={onClick}>
        {children}
      </label>
    </span>
  );
}
