export default class DropdownBehaviour {
  static id = 'dropdown';

  constructor(node) {
    this.node = node;
    this.triggerNode = node.querySelector('button[aria-expanded]');
    const controls = this.triggerNode.getAttribute('aria-controls');
    this.dropdownNode = controls
      ? document.querySelector(`#${controls}`)
      : this.triggerNode.nextElementSibling;

    this.triggerNode.addEventListener('click', this.handleClickTrigger);
  }

  toggle(open) {
    if (open === undefined) {
      open = !!this.dropdownNode.hidden;
    }

    this.triggerNode.setAttribute('aria-expanded', open ? 'true' : 'false');

    if (open) {
      this.dropdownNode.removeAttribute('hidden');
    } else {
      this.dropdownNode.setAttribute('hidden', true);
    }

    if (open) {
      document.addEventListener('scroll', this.handleClickOutside, true);
      document.addEventListener('click', this.handleClickOutside, true);
    } else {
      document.removeEventListener('click', this.handleClickOutside, true);
    }
  }

  handleClickTrigger = (event) => {
    this.toggle();
  };

  handleClickOutside = (event) => {
    if (
      event.target === this.triggerNode ||
      event.target === this.dropdownNode ||
      this.triggerNode.contains(event.target) ||
      this.dropdownNode.querySelector('div').contains(event.target)
    ) {
      return;
    }

    this.toggle(false);
  };
}
