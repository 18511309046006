export default class MenuButtonBehaviour {
  static id = 'menu-button';

  constructor(node) {
    this.node = node;
    this.triggerNode = node.querySelector('button[aria-expanded]');
    this.menuNode = this.triggerNode.nextElementSibling;
    if (this.menuNode) {
      this.menuItems = [...this.menuNode.querySelectorAll('a')];
    }

    this.triggerNode.addEventListener('click', this.handleClickTrigger);
  }

  toggle(open) {
    if (open === undefined) {
      open = !!this.menuNode.hidden;
    }

    this.triggerNode.setAttribute('aria-expanded', open ? 'true' : 'false');

    if (open) {
      this.menuNode.removeAttribute('hidden');
      this.menuItems.forEach((element) => element.removeAttribute('tabindex'));
    } else {
      this.menuNode.setAttribute('hidden', true);
      this.menuItems.forEach((element) => element.setAttribute('tabindex', '-1'));
    }

    if (open) {
      document.addEventListener('scroll', this.handleClickOutside, true);
      document.addEventListener('click', this.handleClickOutside, true);
    } else {
      document.removeEventListener('click', this.handleClickOutside, true);
    }
  }

  handleClickTrigger = () => {
    this.toggle();
  };

  handleClickOutside = (event) => {
    if (
      event.target === this.triggerNode ||
      event.target === this.menuNode ||
      this.triggerNode.contains(event.target) ||
      this.menuNode.contains(event.target)
    ) {
      return;
    }

    this.toggle(false);
  };
}
