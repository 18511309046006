import React from 'react';
import { render } from 'react-dom';
import 'element-closest/browser.js';
import 'focus-visible';
import 'lazysizes';

import App from './app.js';
import { supportsResponsiveImages } from './utils/supports.js';

import './styles.js';

const supportedBrowserRegexp = require('./supported-regexp.js');

if (!supportsResponsiveImages()) {
  import('picturefill');
}

// Patch missing SVGElement onmouseenter/leave event listener properties in IE11
// XXX: This may also run in old version of iOS Safari for some reason
if (SVGElement && !('onmouseenter' in SVGElement.prototype)) {
  try {
    Object.defineProperty(SVGElement.prototype, 'onmouseenter', { writable: true });
    Object.defineProperty(SVGElement.prototype, 'onmouseleave', { writable: true });
  } catch (err) {
    console.error(err);
  }
}

function main() {
  const node = document.createElement('div');
  document.body.appendChild(node);
  render(<App />, node);
}

if (!supportedBrowserRegexp.test(navigator.userAgent)) {
  document.getElementById('unsupported').style.display = 'block';
} else {
  main();
}

try {
  document
    .querySelector('footer a[href="#cookie-settings"]')
    .addEventListener('click', function(event) {
      event.preventDefault();
      try {
        CookieControl.open();
      } catch (err) {}
    });
} catch (err) {}
