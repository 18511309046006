// TODO: Make balanced
export function breakStringIntoLines(str, count) {
  let lines = [];

  if (str.length > count) {
    const words = str.split(' ');
    let buffer = '';

    while (words.length) {
      const word = words.shift();

      if (buffer.length + word.length + 1 < count) {
        buffer += word + ' ';
      } else {
        lines.push(buffer);
        buffer = word + ' ';
      }
    }

    if (buffer.length) {
      lines.push(buffer);
    }
  } else {
    lines = [str];
  }

  return lines;
}
