import React, { useRef, useState, useEffect } from 'react';
import { scaleLinear } from 'd3-scale';
import { SizeMe } from 'react-sizeme';

import Icon from '../Icon';
import Loader from '../Loader';

import './styles.scss';

export function ImageSlider({ images, width, marginX = 0 }) {
  const rootNode = useRef(null);
  const [sliderPos, setSliderPos] = useState(0.2);
  const [dragging, setDragging] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const [dragged, setDragged] = useState(false);

  const scale = scaleLinear()
    .domain([0, 1])
    .range([marginX, width - marginX])
    .clamp(true);

  const handleSlider = (e) => {
    if (!rootNode.current || !dragging) {
      return;
    }

    setDragged(true);

    // Calc Cursor Position from the left edge of the viewport
    const cursorXfromViewport = e.touches ? e.touches[0].pageX : e.pageX;
    // Calc Cursor Position from the left edge of the window (consider any page scrolling)
    const cursorXfromWindow = cursorXfromViewport - window.pageXOffset;
    // Calc Cursor Position from the left edge of the image
    const rect = rootNode.current.getBoundingClientRect();
    const pos = cursorXfromWindow - rect.left;

    setSliderPos(scale.invert(pos));
  };

  const handleRangeChange = (e) => {
    setSliderPos(e.currentTarget.value);
  };

  const handleClick = (e) => {
    if (dragged) {
      setDragged(false);
      return;
    }

    setSliderPos(scale.invert(e.offsetX));
  };

  const endDrag = () => {
    if (dragging) {
      setDragging(false);
    }
  };

  const startDrag = () => {
    setDragged(false);

    if (!dragging) {
      setDragging(true);
    }
  };

  const sliderX = scale(sliderPos);

  useEffect(() => {
    document.addEventListener('mouseleave', endDrag);
    document.addEventListener('mouseup', endDrag);
    return () => {
      document.removeEventListener('mouseleave', endDrag);
      document.removeEventListener('mouseup', endDrag);
    };
  });

  return (
    <div
      ref={rootNode}
      className="image-slider__main"
      style={{ cursor: dragging ? 'ew-resize' : 'default' }}
      data-state={imagesLoaded < 2 && 'loading'}
      onMouseUp={endDrag}
      onMouseMove={handleSlider}
      onClickCapture={handleClick}
    >
      {imagesLoaded < 2 && <Loader />}
      <img
        className="image-slider__background"
        srcSet={images[1].srcset && images[1].srcset.join(',')}
        src={images[1].src}
        onLoad={() => setImagesLoaded(imagesLoaded + 1)}
        alt={images[1].alt || ''}
      />
      <img
        className="image-slider__overlay"
        srcSet={images[0].srcset && images[0].srcset.join(',')}
        src={images[0].src}
        style={{
          clip: `rect(auto, ${sliderX}px, auto, auto)`,
        }}
        onLoad={() => setImagesLoaded(imagesLoaded + 1)}
        alt={images[0].alt || ''}
      />
      {/* TOOD: Make class names consistent */}
      {imagesLoaded >= 2 && (
        <div
          className="slider"
          style={{
            left: `${sliderX}px`,
          }}
        >
          <div className="slider__bar">
            <div className="slider__post" onMouseDown={startDrag} />
            <input
              className="slider__range"
              type="range"
              step="0.1"
              min="0"
              max="1"
              value={sliderPos}
              onFocus={startDrag}
              onBlur={endDrag}
              onChange={handleRangeChange}
            />
            <span
              className="slider__handle"
              onTouchStart={startDrag}
              onTouchEnd={endDrag}
              onTouchMove={handleSlider}
              onMouseDown={startDrag}
            >
              <Icon id="chevron-double-small" />
              <span className="slider__handle__hitarea" />
            </span>
          </div>
          <span className="slider__label slider__label--left">{images[0].label}</span>
          <span className="slider__label">{images[1].label}</span>
        </div>
      )}
    </div>
  );
}

export default function SizedImageSlider(props) {
  return (
    <SizeMe monitorHeight>
      {({ size }) => (
        <div className="image-slider">
          {size.width && size.height && (
            <div>
              <ImageSlider width={size.width} height={size.height} left={null} {...props} />
            </div>
          )}
        </div>
      )}
    </SizeMe>
  );
}
