import React from 'react';
import './styles.scss';

export default function ChartHeader({ data, year, measure }) {
  return (
    <p className="chart-header">
      <span className="chart-header__data">
        {data}
        <span>{year}</span>
      </span>
      <span className="chart-header__meta">{measure}</span>
    </p>
  );
}
