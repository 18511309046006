import React from 'react';

import './styles.scss';

export default function LegendScale({ title, items, labelStart, labelEnd, inline }) {
  return (
    <div className={`legend-scale${inline ? ' legend-scale--inline' : ''}`} aria-hidden="true">
      <h3>{title}</h3>
      <div className="legend-scale__main">
        <ul className="legend-scale__items">
          {items.map((item) => {
            return (
              <li
                key={item.id}
                className={`legend-scale__item legend-scale__item--${item.id}`}
                data-key={item.id}
              />
            );
          })}
        </ul>
        <div className="legend-scale__label legend-scale__label--start">{labelStart}</div>
        <div className="legend-scale__label legend-scale__label--end">{labelEnd}</div>
      </div>
    </div>
  );
}
