import React from 'react';
import './styles.scss';

export default function Legend({ items }) {
  return (
    <ul className="legend">
      {items.map((item) => {
        return (
          <li key={item.id} className="legend__item" data-key={item.id}>
            <i></i>
            <span>{item.title}</span>
          </li>
        );
      })}
    </ul>
  );
}
