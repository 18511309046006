import bridge from '../../../utils/bridge.js';

class FeedbackLinkBehaviour {
  static id = 'feedback-link';

  constructor(node) {
    const url = node.dataset.feedbackUrl;

    node.addEventListener('click', function(event) {
      const target = event.target;

      if (!target.matches('a') || !target.href.endsWith(url)) {
        return;
      }

      event.preventDefault();

      const policy = target.dataset.policy;
      const country = target.dataset.country;

      bridge.emit('feedback:open', { policy, country });
    });
  }
}

export default FeedbackLinkBehaviour;
