import React from 'react';
import clsx from 'clsx';

import { roundTo } from '../../../utils/math.js';

import './styles.scss';

export default function RangeInput({
  className,
  min,
  max,
  trackMax,
  step = 1,
  formattedValue,
  onHoverDeadZone,
  ...otherProps
}) {
  let minNum = parseInt(min, 10);
  let maxNum = parseInt(max, 10);
  let trackMaxNum = trackMax !== undefined ? parseInt(trackMax, 10) : maxNum;

  if (Number.isNaN(minNum)) {
    minNum = 0;
  }

  if (Number.isNaN(maxNum)) {
    maxNum = 1;
  }

  if (Number.isNaN(trackMaxNum)) {
    trackMaxNum = 1;
  }

  const inputWidthPc = (maxNum - minNum) / (trackMaxNum - minNum);

  const handleHoverDeadZone = (show) => () => {
    onHoverDeadZone(show);
  };

  // Prevent touch events on dead zone from propagating to mouse events
  const handleTouchDeadZone = (event) => {
    event.preventDefault();
  };

  const handleInputTouchMove = (event) => {
    const rect = event.target.getBoundingClientRect();
    if (event.touches[0].pageX > rect.left + rect.width) {
      onHoverDeadZone(true);
      // Try to dispatch a custom touch event to trigger tooltip layout
      try {
        document.dispatchEvent(
          new CustomEvent('TooltipPosition', {
            bubbles: true,
            detail: {
              pageX: window.scrollX + rect.left + rect.width,
              pageY: window.scrollY + rect.top + rect.height / 2,
            },
          })
        );
      } catch (err) {}
    } else {
      onHoverDeadZone(false);
    }
  };

  const handleInputTouchRelease = (event) => {
    onHoverDeadZone(false);
  };

  // On iOS clicking the input track doesn't move the handle, so try to
  // polyfill that behaviour
  const handleInputClick = (event) => {
    // FIXME: This doesn't match on iOS 13 on iPad
    if (!/iPad|iPhone|iPod/.test(navigator.platform)) {
      return;
    }

    const range = maxNum - minNum;
    const rect = event.target.getBoundingClientRect();
    const handleWidth = rect.height;
    const x = event.offsetX - handleWidth / 2;
    const width = rect.width - handleWidth;
    const pc = x / width;
    const value = minNum + range * pc;
    const roundedValue = roundTo(value, step);
    event.target.value = roundedValue;
    event.target.dispatchEvent(new Event('change'));
  };

  return (
    <div className={`rangeinput ${className || ''}`}>
      <div className="rangeinput__main">
        <span className="rangeinput__track">
          {step && renderTicks(minNum, maxNum, trackMaxNum, step)}
        </span>
        {trackMaxNum > maxNum && (
          <div
            className="rangeinput__deadzone"
            style={{ width: `${(1 - inputWidthPc) * 100}%` }}
            onTouchStart={handleTouchDeadZone}
            onMouseEnter={onHoverDeadZone && handleHoverDeadZone(true)}
            onMouseLeave={onHoverDeadZone && handleHoverDeadZone()}
          />
        )}
        <div className="rangeinput__input" style={{ width: `${inputWidthPc * 100}%` }}>
          <div className="rangeinput__input__main">
            <input
              type="range"
              min={minNum}
              max={maxNum}
              step={step}
              onClick={handleInputClick}
              onTouchMove={onHoverDeadZone && handleInputTouchMove}
              onTouchEnd={onHoverDeadZone && handleInputTouchRelease}
              onTouchCancel={onHoverDeadZone && handleInputTouchRelease}
              {...otherProps}
            />
          </div>
        </div>
      </div>
      {formattedValue !== undefined ? (
        <div
          className="rangeinput__value"
          aria-hidden="true"
          style={{ minWidth: `${max.toString().length}ch` }}
        >
          {formattedValue}
        </div>
      ) : null}
    </div>
  );
}

function renderTicks(min, max, trackMax, step) {
  const range = trackMax - min;
  const threshold = max - min;
  return Array(Math.round(range / step))
    .fill(1)
    .map((n, i) => {
      return (
        <div
          key={`tick-${i}`}
          className={clsx('rangeinput__tick', i >= threshold && 'rangeinput__tick--disabled')}
        />
      );
    });
}
