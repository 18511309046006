const FONT_SIZE = 96;
const HEIGHT = 80;

class StatisticBehaviour {
  static id = 'statistic';

  constructor(node) {
    this.node = node;
    this.contentWidth = null;

    // TODO: Debounce resize
    window.addEventListener('resize', this.handleWindowResize);

    this.layout();
  }

  layout() {
    const rect = this.node.getBoundingClientRect();

    // Only measure content width once
    if (this.contentWidth === null) {
      let contentWidth = 0;
      const nodes = this.node.querySelectorAll('[data-ref]');
      nodes.forEach((node) => {
        contentWidth += node.getBoundingClientRect().width;
      });
      this.contentWidth = contentWidth;
    }

    if (this.contentWidth > rect.width) {
      const scale = rect.width / this.contentWidth;
      this.node.style.fontSize = FONT_SIZE * scale + 'px';
      this.node.style.height = HEIGHT * scale + 'px';
      this.node.style.lineHeight = HEIGHT * scale + 'px';
    } else {
      this.node.removeAttribute('style');
    }
  }

  handleWindowResize = () => {
    this.layout();
  };
}

export default StatisticBehaviour;
