import React, { useState } from 'react';

import BarChart from 'components/react/BarChart';
import Legend from 'components/react/Legend';
import PortalTooltip from 'components/react/PortalTooltip';
import ChartReadout from 'components/react/ChartReadout';
import SourceLabel from 'components/react/SourceLabel';
import ChartHeader from 'components/react/ChartHeader';
import ChartContainer from 'components/react/ChartContainer';

import { groupAndMergeData } from '../../utils/data.js';
import { formatVolume } from '../../utils/formatters.js';

import './styles.scss';

const DEFAULT_YEAR = 2018;

// > The ‘upper estimate’ should be inclusive of the ‘lower estimate’.
// > So yes, the lower estimate ‘overlap’ the upper estimate bar.
function adjustIllegalData(data) {
  return data.map((datum) => {
    return {
      ...datum,
      illegalUpperAdjusted:
        datum['Illegal (upper estimate)'] - (datum['Illegal (lower estimate)'] || 0),
    };
  });
}

export default function ExportsVolumeContainer({ data, xDomain, source }) {
  const [activeX, setActiveX] = useState(DEFAULT_YEAR);
  const [hovered, setHovered] = useState(false);
  const [deadZoneHovered, setDeadZoneHovered] = useState(false);

  const mergedData = groupAndMergeData(data, 'year', 'type', 'total');
  const adjustedData = adjustIllegalData(mergedData);

  const datum = adjustedData.find((d) => d.year === activeX);
  const total = datum ? datum['Legal'] + datum['Illegal (upper estimate)'] : 0;
  const legalPcStr = datum ? ` (${((datum['Legal'] / total) * 100).toFixed(0)}%)` : '';
  const lowerPcStr = datum
    ? ` (${((datum['Illegal (lower estimate)'] / total) * 100).toFixed(0)}%)`
    : '';
  const upperPcStr = datum
    ? ` (${((datum['Illegal (upper estimate)'] / total) * 100).toFixed(0)}%)`
    : '';
  const tooltipData = {
    Legal: formatVolume(datum['Legal'], true) + legalPcStr,
    'Illegal (lower estimate)': formatVolume(datum['Illegal (lower estimate)'], true) + lowerPcStr,
    'Illegal (upper estimate)': formatVolume(datum['Illegal (upper estimate)'], true) + upperPcStr,
  };

  return (
    <div className="exports-volume-container">
      <ChartHeader
        data={formatVolume(total, true)}
        year={activeX}
        measure="cubic metres (RWE volume)"
      />
      <ChartContainer variant="barchart-tall">
        <BarChart
          data={adjustedData}
          xKey="year"
          xDomain={xDomain}
          yKeys={['Illegal (lower estimate)', 'illegalUpperAdjusted', 'Legal']}
          activeX={activeX}
          hovered={hovered}
          modifier="exports"
          contiguousKeys={['Illegal (lower estimate)', 'illegalUpperAdjusted']}
          onHover={(x, event) => {
            const active = event && (event.type === 'mouseenter' || event.type === 'touchstart');
            setActiveX(active ? x : DEFAULT_YEAR);
            setHovered(active);
          }}
          onHoverDeadZone={(active) => {
            // TODO: Do we need to clear the main hover state?
            setDeadZoneHovered(active === true);
          }}
        />
      </ChartContainer>
      <Legend
        items={[
          { id: 'Legal', title: 'Legal' },
          { id: 'Illegal (upper estimate)', title: 'Illegal (upper estimate)' },
          { id: 'Illegal (lower estimate)', title: 'Illegal (lower estimate)' },
        ]}
      />
      {source && <SourceLabel html={source} />}
      {hovered && (
        <PortalTooltip>
          <ChartReadout
            title={() => {
              return (
                <>
                  Exports {activeX}, RWE m<sup>3</sup>
                </>
              );
            }}
            data={tooltipData}
            keys={['Legal', 'Illegal (upper estimate)', 'Illegal (lower estimate)']}
            labels={{
              Legal: 'Legal',
              'Illegal (lower estimate)': 'Illegal (lower estimate)',
              'Illegal (upper estimate)': 'Illegal (upper estimate)',
            }}
            withSwatches
          />
        </PortalTooltip>
      )}
      {deadZoneHovered && (
        <PortalTooltip variant="narrow">
          <ChartReadout body={__('country-trade-forthcoming')} />
        </PortalTooltip>
      )}
    </div>
  );
}
