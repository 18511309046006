import { openWindow } from '../../../utils/windows.js';

class ShareButtonsBehaviour {
  static id = 'share-buttons';

  constructor(node) {
    this.node = node;

    this.url = window.location.href.replace(/#.+$/, '');
    if (window.__ENV__) {
      // TODO: Check defaults are never used?
      this.twitterShareText = __ENV__.twitterShareText || '';
      this.twitterShareVia = __ENV__.twitterShareVia || '';
      this.emailShareSubject = __ENV__.emailShareSubject || '';
      this.emailShareBody = __ENV__.emailShareBody || '';
    } else {
      this.twitterShareText = '';
      this.twitterShareVia = '';
      this.emailShareSubject = '';
      this.emailShareBody = '';
    }
    this.updateURLs();

    this.node.addEventListener('click', this.handleClick);
  }

  updateURLs() {
    const encodedUrl = encodeURIComponent(this.url);
    const emailShareSubject = encodeURIComponent(this.emailShareSubject);
    const emailShareBody = encodeURIComponent(this.emailShareBody);
    const twitterShareText = encodeURIComponent(this.twitterShareText);
    const twitterShareVia = encodeURIComponent(this.twitterShareVia.replace('@', ''));
    const nodes = this.node.querySelectorAll('[data-ref]');

    nodes.forEach((node) => {
      let href = '';
      switch (node.dataset.ref) {
        case 'email':
          href = `mailto:?subject=${emailShareSubject}&body=${emailShareBody}%0A%0A${encodedUrl}`;
          break;
        case 'facebook':
          href = `https://www.facebook.com/sharer.php?u=${encodedUrl}`;
          break;
        case 'linkedin':
          href = `https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}`;
          break;
        case 'twitter':
          href = `https://twitter.com/intent/tweet?text=${twitterShareText}&url=${encodedUrl}&via=${twitterShareVia}`;
          break;
        case 'whatsapp':
          href = `https://wa.me/?text=${encodedUrl}`;
          break;
      }
      node.setAttribute('href', href);
    });
  }

  handleClick = (event) => {
    const anchor = event.target.closest('a');

    if (!anchor || anchor.protocol === 'mailto:') {
      return;
    }

    event.preventDefault();
    openWindow(anchor.href);
  };
}

export default ShareButtonsBehaviour;
