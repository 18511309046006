import React from 'react';
import { scaleLinear, scaleQuantile } from 'd3-scale';
import { SizeMe } from 'react-sizeme';
import clsx from 'clsx';

import { breakStringIntoLines } from '../../../utils/strings.js';
import { eventMatchesPointerType } from '../../../utils/pointer.js';

import './styles.scss';

const FLOW_Y_OFFSET_MAX = 36;
const FLOW_Y_OFFSET_MIN = 22;
const FLOW_Y_OFFSET_MULTIPLER = 36 / 436;
const FLOW_MARGIN = 1;
const CHARACTER_WIDTH = 8;
const HITBOX_PADDING = 4;

function renderFlowPath(y1, y2, x1, x2, startThickness, endThickness) {
  const xh = (x1 + x2) * 0.5;
  const xh1 = (x1 + x2) * 0.25;
  const xh2 = (x1 + x2) * 0.75;

  return `
    M${x1},${y1}
    L${xh1},${y1}
    C${xh},${y1} ${xh},${y2} ${xh2},${y2}
    L${x2},${y2}
    L${x2},${y2 + endThickness}
    L${xh2},${y2 + endThickness}
    C${xh},${y2 + endThickness} ${xh},${y1 + startThickness} ${xh1},${y1 + startThickness}
    L${x1},${y1 + startThickness}
    L${x1},${y1}
  `;
}

export function SankeyChart({
  width,
  height,
  data,
  direction,
  numFlows,
  label,
  dataKey,
  colourKey,
  renderLabel,
  activeFlow,
  onHover,
}) {
  const flowYOffset = Math.max(
    FLOW_Y_OFFSET_MIN,
    Math.min(FLOW_Y_OFFSET_MAX, height * FLOW_Y_OFFSET_MULTIPLER)
  );
  const dataTotal = data.reduce((total, datum) => {
    return total + datum[dataKey];
  }, 0);
  const totalFlows = numFlows || data.length;
  const maxY = height - flowYOffset * totalFlows - FLOW_MARGIN * (totalFlows - 1);
  const scaleY = scaleLinear()
    .domain([0, dataTotal])
    // XXX: Use 0.5 for range min to try to ensure small flow paths are visible
    .range([0.5, maxY]);
  const scalePercentage = scaleLinear()
    .domain([0, dataTotal])
    .range([0, 1]);
  // TODO: Read colours from Sass?
  const scaleColour = scaleQuantile()
    .domain([0, 1])
    .range(['#CCD9AD', '#D2BBAA', '#DF7FA4', '#ED3B9C', '#A61780']);
  const estimatedMaxLabelCharacters = width / 3 / CHARACTER_WIDTH;
  const labelLines = breakStringIntoLines(label, estimatedMaxLabelCharacters);

  let yOffset = 0;

  const pathNodes = [];
  const labelNodes = [];
  data.forEach((datum, i) => {
    const height = scaleY(datum[dataKey] || 0);
    const y = flowYOffset * i;
    const y1 = direction === 'from' ? 0 : y;
    const y2 = direction === 'from' ? y : 0;
    const hitboxY1 = direction === 'from' ? 0 - FLOW_MARGIN : y - FLOW_MARGIN - HITBOX_PADDING;
    const hitboxY2 = direction === 'from' ? y - FLOW_MARGIN - HITBOX_PADDING : 0 - FLOW_MARGIN;
    const hitboxThickness = height + FLOW_MARGIN * 2;
    const hitboxStartThickness =
      direction === 'from' ? hitboxThickness : hitboxThickness + flowYOffset;
    const hitboxEndThickness =
      direction === 'from' ? hitboxThickness + flowYOffset : hitboxThickness;

    const handleHover =
      onHover &&
      ((event) => {
        if (!eventMatchesPointerType(event)) {
          return;
        }

        onHover(event.type === 'mouseenter' || event.type === 'touchstart' ? i : null, event);
      });

    const pathNode = (
      <g
        className={clsx('sankeychart__flow', activeFlow === i && 'sankeychart__flow--active')}
        transform={`translate(0, ${yOffset})`}
      >
        <path
          className="sankeychart__flow__path"
          d={renderFlowPath(y1, y2, 0, width, height, height)}
          fill={scaleColour(datum[colourKey])}
        />
        <path
          className="sankeychart__flow__hitbox"
          d={renderFlowPath(hitboxY1, hitboxY2, 0, width, hitboxStartThickness, hitboxEndThickness)}
          onMouseEnter={handleHover}
          onMouseLeave={handleHover}
          onTouchStart={handleHover}
          onTouchEnd={handleHover}
          onTouchCancel={handleHover}
        />
      </g>
    );

    const labelNode = (
      <g className="sankeychart__flow-label" transform={`translate(0, ${yOffset})`}>
        <text
          x={direction === 'from' ? width : 0}
          y={height + y}
          dy="1em"
          textAnchor={direction === 'from' ? 'end' : 'start'}
        >
          {renderLabel ? (
            renderLabel(datum, scalePercentage(datum[dataKey]))
          ) : (
            <tspan>{datum[dataKey]}</tspan>
          )}
        </text>
      </g>
    );

    yOffset = yOffset + height + FLOW_MARGIN;

    pathNodes.push(pathNode);
    labelNodes.push(labelNode);
  });

  return (
    <svg
      className={clsx(
        'sankeychart__graphic',
        activeFlow !== null && 'sankeychart__graphic--hovered'
      )}
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
    >
      <text
        className="sankeychart__label"
        x={direction === 'from' ? 0 : width}
        y={maxY + FLOW_MARGIN * (totalFlows - 1)}
        dy="1em"
        textAnchor={direction === 'from' ? 'start' : 'end'}
      >
        {labelLines.map((line) => {
          return (
            <tspan key={line} x={direction === 'from' ? 0 : width} dy="1em">
              {line}
            </tspan>
          );
        })}
      </text>
      {pathNodes}
      {labelNodes}
    </svg>
  );
}

export default function SizedSankeyChart(props) {
  return (
    <SizeMe monitorHeight>
      {({ size }) => (
        <div className="sankeychart">
          {size.width && size.height && (
            <SankeyChart width={size.width} height={size.height} {...props} />
          )}
        </div>
      )}
    </SizeMe>
  );
}
