import React from 'react';
import clsx from 'clsx';
import './styles.scss';

import ErrorMessage from '../ErrorMessage';
import Icon from '../Icon';

export default function TextInput({
  label,
  name,
  onInput,
  placeholder,
  required,
  type,
  value,
  error,
  onClickClear,
}) {
  const handleInput = (e) => onInput(e.target.getAttribute('name'), e.target.value);

  const handleClickClear = () => {
    onClickClear(name);
  };

  return (
    <span className={clsx('text-input', error && 'text-input--error')}>
      <label htmlFor={name} className="text-input__label" onInput={handleInput}>
        {`${label}${required ? ' *' : ''}`}
        <input
          id={name}
          name={name}
          className="text-input__field"
          type={type}
          placeholder={placeholder}
          value={value}
          required={required}
        />
        <button
          aria-label={`clear ${name} input`}
          onClick={handleClickClear}
          type="button"
          className={clsx('text-input__clear', value && 'text-input__clear--active')}
        >
          <Icon id="clear-type" />
        </button>
      </label>
      {error ? <ErrorMessage message={error} /> : null}
    </span>
  );
}
