import { addChildrenToTab, removeChildrenFromTab } from '../../../utils/components.js';

export default class AccordionBehaviour {
  static id = 'accordion';

  constructor(node) {
    this.node = node;
    this.contentNode = node.nextElementSibling;

    this.node.addEventListener('click', this.toggle);
  }

  toggle = () => {
    const open = this.node.getAttribute('aria-expanded') === 'true';

    if (open) {
      this.animateClose();
    } else {
      this.animateOpen();
    }
  };

  animateClose = () => {
    requestAnimationFrame(() => {
      const contentHeight = this.contentNode.scrollHeight;
      this.contentNode.style.height = `${contentHeight}px`;
      requestAnimationFrame(() => {
        this.contentNode.style.height = '0px';
      });
    });

    this.node.setAttribute('aria-expanded', 'false');
    removeChildrenFromTab(this.contentNode);
  };

  animateOpen = () => {
    const contentHeight = this.contentNode.scrollHeight;
    this.contentNode.style.height = `${contentHeight}px`;

    this.node.setAttribute('aria-expanded', 'true');

    this.contentNode.addEventListener('transitionend', this.handleTransEnd);

    addChildrenToTab(this.contentNode);
  };

  handleTransEnd = () => {
    this.contentNode.style.height = 'auto';
    this.contentNode.removeEventListener('transitionend', this.handleTransEnd);
  };
}
