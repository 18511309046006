import React from 'react';
import './styles.scss';

import DonutChart from '../DonutChart';

function renderLabel(label, value, align = 'left') {
  return (
    <div className={`labelled-donut__label labelled-donut__label--${align}`}>
      <div className="labelled-donut__label__value">{(value * 100).toFixed(0)}%</div>
      {label && <div className="labelled-donut__label__label">{label}</div>}
      <div className="labelled-donut__marker" />
    </div>
  );
}

export default function LabelledDonutChart({ keys, labels, data, ...rest }) {
  return (
    <div className="labelled-donut">
      {renderLabel(labels[keys[1]], data[keys[1]])}
      <div className="labelled-donut__graphic">
        <DonutChart keys={keys} data={data} fillInterior {...rest} />
      </div>
      {renderLabel(labels[keys[0]], data[keys[0]], 'right')}
    </div>
  );
}
