import React, { useState } from 'react';

import BarChart from 'components/react/BarChart';
import SourceLabel from 'components/react/SourceLabel';
import ChartHeader from 'components/react/ChartHeader';
import ChartContainer from 'components/react/ChartContainer';

import { formatThousands } from '../../utils/formatters.js';

import './styles.scss';

export default function EmissionsContainer({ data, xDomain, source }) {
  const [activeX, setActiveX] = useState(2018);
  const [hovered, setHovered] = useState(false);

  const datum = data.find((d) => d.year === activeX);

  return (
    <div className="emissions-container">
      {/* FIXME: Remove lowercasing */}
      <ChartHeader
        data={formatThousands(datum.emissions)}
        year={activeX}
        measure={__('country-use-emissions-unit').toLowerCase()}
      />
      <ChartContainer variant="barchart-shallow">
        <BarChart
          data={data}
          xKey="year"
          xDomain={xDomain}
          yKeys={['emissions']}
          activeX={activeX}
          hovered={hovered}
          onHover={(x, event) => {
            const active = event && (event.type === 'mouseenter' || event.type === 'touchstart');
            setActiveX(active ? x : 2018);
            setHovered(active);
          }}
        />
      </ChartContainer>
      {source && <SourceLabel html={source} />}
    </div>
  );
}
