import React, { useState } from 'react';

import LabelledDonutChart from 'components/react/LabelledDonutChart';
import PortalTooltip from 'components/react/PortalTooltip';
import ChartReadout from 'components/react/ChartReadout';
import SourceLabel from 'components/react/SourceLabel';
import ChartHeader from 'components/react/ChartHeader';
import ChartContainer from 'components/react/ChartContainer';

import { formatPercentage, formatVolume } from '../../utils/formatters.js';

import './styles.scss';

const LABELS = { exports: 'Exports', consumption: 'Domestic' };
const TOOLTIP_LABELS = { exports: 'Exports', consumption: 'Domestic consumption' };

export default function TimberConsumptionContainer({ data, source }) {
  const [hoveredKey, setHoveredKey] = useState(null);

  const hasDetailedData = data.length > 1;
  const totalData = !hasDetailedData ? data[0] : data.find((datum) => !datum.type);
  const donutData = {
    consumption: totalData.consumption / totalData.production,
    exports: totalData.exports / totalData.production,
  };
  const tooltipData =
    hasDetailedData && hoveredKey
      ? data.reduce((acc, datum) => {
          if (!datum.type) {
            return acc;
          }
          acc[datum.type] = formatPercentage(datum[hoveredKey] / totalData[hoveredKey]);
          return acc;
        }, {})
      : null;

  return (
    <div className="timber-consumption-container">
      <ChartHeader
        data={formatVolume(hoveredKey ? totalData[hoveredKey] : totalData.production, true, 1)}
        year="2014"
        measure="cubic metres (RWE volume)"
      />
      <ChartContainer variant="donut">
        <LabelledDonutChart
          data={donutData}
          keys={['exports', 'consumption']}
          labels={LABELS}
          radius={104}
          thickness={52}
          activeKey={hoveredKey}
          hovered={!!hoveredKey}
          onHover={(key) => {
            setHoveredKey(key);
          }}
        />
      </ChartContainer>
      {source && <SourceLabel html={source} />}
      {tooltipData && hoveredKey && (
        <PortalTooltip>
          <ChartReadout
            title={TOOLTIP_LABELS[hoveredKey]}
            data={tooltipData}
            keys={['tropical', 'plantation']}
            labels={{
              tropical: 'Tropical timber',
              plantation: 'Plantation timber',
            }}
          />
        </PortalTooltip>
      )}
    </div>
  );
}
