import bridge from '../../../utils/bridge.js';

export default class FeedbackBehaviour {
  static id = 'feedback';

  constructor(node) {
    node.addEventListener('click', function() {
      bridge.emit('feedback:open');
    });
  }
}
