import React from 'react';
import clsx from 'clsx';

import './styles.scss';

function getSegmentPath(centerX, centerY, outerRadius, innerRadius, startAngle, endAngle) {
  const largeArcFlag = endAngle - startAngle > Math.PI ? 1 : 0;
  return `
    M
    ${centerX + outerRadius * Math.cos(startAngle)}
    ${centerY + outerRadius * Math.sin(startAngle)}
    A
    ${outerRadius},${outerRadius},0,${largeArcFlag},1,
    ${centerX + outerRadius * Math.cos(endAngle)}
    ${centerY + outerRadius * Math.sin(endAngle)}
    L
    ${centerX + innerRadius * Math.cos(endAngle)}
    ${centerY + innerRadius * Math.sin(endAngle)}
    A
    ${innerRadius},${innerRadius},0,${largeArcFlag},0,
    ${centerX + innerRadius * Math.cos(startAngle)}
    ${centerY + innerRadius * Math.sin(startAngle)}
    L
    ${centerX + outerRadius * Math.cos(startAngle)}
    ${centerX + outerRadius * Math.sin(startAngle)}
    Z
  `;
}

export default function DonutChart({
  keys,
  data,
  radius,
  thickness,
  fillBg,
  fillInterior,
  activeKey,
  hovered,
  onHover,
}) {
  const innerRadius = radius - thickness;
  const width = radius * 2;
  const centerX = radius;
  const centerY = radius;
  const zeroAngle = 0;
  // XXX: Full angle must be slightly less than 2π in order to draw arc
  const fullAngle = Math.PI * 2 - 0.0001;

  const segments = [];
  let startAngle = zeroAngle;
  let endAngle = zeroAngle;
  keys.forEach((key) => {
    startAngle = endAngle;
    endAngle = startAngle + Math.PI * 2 * data[key];
    const handleHover = (event) => {
      const active = event.type === 'mouseenter' || event.type === 'touchstart';
      onHover(active ? key : null);
    };
    segments.push(
      <path
        className={clsx('donut__segment', { 'donut__segment--active': activeKey === key })}
        data-key={key}
        d={getSegmentPath(centerX, centerY, radius, innerRadius, startAngle, endAngle)}
        onMouseEnter={onHover && handleHover}
        onMouseLeave={onHover && handleHover}
        onTouchStart={onHover && handleHover}
        onTouchEnd={onHover && handleHover}
        onTouchCancel={onHover && handleHover}
      />
    );
  });

  return (
    <svg
      className={clsx('donut', { 'donut--hovered': hovered })}
      viewBox={`0 0 ${width} ${width}`}
      width={width}
      height={width}
    >
      {fillBg && (
        <path
          className="donut__bg"
          d={getSegmentPath(centerX, centerY, radius, innerRadius, zeroAngle, fullAngle)}
        />
      )}
      {fillInterior && <circle cx={radius} cy={radius} r={radius} fill="#fff" />}
      <g className="donut__segments" transform={`rotate(-90, ${centerX}, ${centerY})`}>
        {segments}
      </g>
    </svg>
  );
}

DonutChart.defaultProps = {
  radius: 50,
  thickness: 10,
};
