import React from 'react';
import clsx from 'clsx';

import { FORMAT_EMPTY_PLACEHOLDER } from '../../../constants.js';

import './styles.scss';

export default function ChartReadout({
  data,
  keys,
  labels,
  bars,
  swatchKeys,
  withSwatches,
  skipMissing,
  title,
  body,
}) {
  const keysToRender = !keys && labels ? Object.keys(labels) : keys;

  return (
    <div className="chart-readout">
      {title && (
        <h4 className="chart-readout__title">{typeof title === 'string' ? title : title()}</h4>
      )}
      {body && (
        <div className="chart-readout__body">
          <p>{body}</p>
        </div>
      )}
      {data && (
        <ul className="chart-readout__list">
          {keysToRender.reduce((items, key) => {
            if ((skipMissing && data[key] === undefined) || data[key] === null) {
              return items;
            }

            items.push(
              <li
                className={clsx(
                  'chart-readout__item',
                  data[key] === FORMAT_EMPTY_PLACEHOLDER && 'chart-readout__item--placeholder'
                )}
                key={key}
              >
                <span>
                  {withSwatches && (
                    <span
                      className="chart-readout__swatch"
                      data-key={swatchKeys ? swatchKeys[key] : key}
                    />
                  )}
                  {labels[key]}
                </span>
                <span>{data[key]}</span>
                {bars && bars[key] && (
                  <span className="chart-readout__bar" style={{ backgroundColor: bars[key] }} />
                )}
              </li>
            );
            return items;
          }, [])}
        </ul>
      )}
    </div>
  );
}
