import React from 'react';
import clsx from 'clsx';

import { sections, illegalitySections } from '../GovernanceScoresChart/constants';

import './styles.scss';

export function GovernanceBarCell({ index, active }) {
  return (
    <div
      className={clsx(
        'governance-chart-bar__cell-container',
        active && 'governance-chart-bar__cell-container--active'
      )}
    >
      <div
        className={clsx(
          'governance-chart-bar__cell',
          sections[index] && `governance-chart-bar__cell--${sections[index]}`
        )}
        data-index={index}
      />
    </div>
  );
}

export function GovernanceBar({ height, score, max = 5, flagged, modifier }) {
  const labels = modifier === 'legality' ? illegalitySections : sections;
  const renderBars = () => {
    const bars = [];
    for (let i = 0; i < max; i++) {
      let active = false;
      if (i < score) {
        active = true;
      }
      bars.push(<GovernanceBarCell index={i} active={active} />);
    }
    bars.push(<span className="governance-chart-bar__label">{labels[score - 1]}</span>);
    return bars;
  };

  return (
    <div
      className={`
        governance-chart-bar__container
        governance-chart-bar__cell--${height}
        ${flagged ? 'governance-chart-bar--flagged' : ''}
        ${modifier ? `governance-chart-bar--${modifier}` : ''}
      `}
      data-max={max}
    >
      {score ? (
        renderBars()
      ) : (
        <span className="governance-chart-bar__coming-soon">Coming soon…</span>
      )}
    </div>
  );
}
