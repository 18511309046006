import React, { useState } from 'react';

import BarChart from 'components/react/BarChart';
import Legend from 'components/react/Legend';
import PortalTooltip from 'components/react/PortalTooltip';
import ChartReadout from 'components/react/ChartReadout';
import SourceLabel from 'components/react/SourceLabel';
import ChartHeader from 'components/react/ChartHeader';
import ChartContainer from 'components/react/ChartContainer';

import { groupAndMergeData } from '../../utils/data.js';
import { formatVolume } from '../../utils/formatters.js';

import './styles.scss';

export default function ImportsVolumeContainer({ data, xDomain, source }) {
  const [activeX, setActiveX] = useState(2014);
  const [hovered, setHovered] = useState(false);
  const [deadZoneHovered, setDeadZoneHovered] = useState(false);

  const mergedData = groupAndMergeData(data, 'year', 'type', 'total');

  const datum = mergedData.find((d) => d.year === activeX);
  const total = datum ? datum['Legal'] + datum['Illegal'] : 0;
  const tooltipData = {
    Legal: formatVolume(datum['Legal'], true),
    Illegal: formatVolume(datum['Illegal'], true),
  };

  return (
    <div className="imports-volume-container">
      <ChartHeader
        data={formatVolume(total, true)}
        year={activeX}
        measure="cubic metres (RWE volume)"
      />
      <ChartContainer variant="barchart">
        <BarChart
          data={mergedData}
          xKey="year"
          xDomain={xDomain}
          yKeys={['Legal', 'Illegal']}
          activeX={activeX}
          hovered={hovered}
          onHover={(x, event) => {
            const active = event && (event.type === 'mouseenter' || event.type === 'touchstart');
            setActiveX(active ? x : 2014);
            setHovered(active);
          }}
          onHoverDeadZone={(active) => {
            // TODO: Do we need to clear the main hover state?
            setDeadZoneHovered(active === true);
          }}
        />
      </ChartContainer>
      <Legend
        items={[
          { id: 'Legal', title: 'Legal' },
          { id: 'Illegal', title: 'Illegal' },
        ]}
      />
      {source && <SourceLabel html={source} />}
      {hovered && (
        <PortalTooltip>
          <ChartReadout
            title={() => (
              <>
                Timber imports {activeX}, RWE m<sup>3</sup>
              </>
            )}
            data={tooltipData}
            keys={['Legal', 'Illegal']}
            labels={{ Legal: 'Legal', Illegal: 'Illegal' }}
            withSwatches
          />
        </PortalTooltip>
      )}
      {deadZoneHovered && (
        <PortalTooltip variant="narrow">
          <ChartReadout body={__('country-trade-forthcoming')} />
        </PortalTooltip>
      )}
    </div>
  );
}
