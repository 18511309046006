import React from 'react';
import './styles.scss';

export default function Button({ label, colour, size, ...restProps }) {
  return (
    <button
      className={`button${colour ? ` button--${colour}` : ''}${size ? ` button--${size}` : ''}`}
      {...restProps}
    >
      {label}
    </button>
  );
}
