import { supportsPassiveEvents } from './supports.js';

let pointerType = null;

// Only act on mousemove event if its the first relevant event because
// Android Chrome will sometimes fire it after touch events

function handleMoveMove() {
  document.removeEventListener('mousemove', handleMoveMove, true);
  pointerType = 'mouse';
}

function handleTouchStart() {
  document.removeEventListener('mousemove', handleMoveMove, true);
  pointerType = 'touch';
}

function handleMouseDown() {
  document.removeEventListener('mousemove', handleMoveMove, true);
  pointerType = 'mouse';
}

document.addEventListener(
  'touchstart',
  handleTouchStart,
  supportsPassiveEvents ? { capture: true, passive: true } : true
);
document.addEventListener('mousemove', handleMoveMove, true);
document.addEventListener('mousedown', handleMouseDown, true);

export function getPointerType() {
  return pointerType;
}

export function eventMatchesPointerType(event) {
  return pointerType && pointerType === event.type.substr(0, 5);
}
