import React, { useEffect, useReducer } from 'react';

import TextInput from 'components/react/TextInput';
import TextareaInput from 'components/react/TextareaInput';
import SelectInput from 'components/react/SelectInput';
import Icon from 'components/react/Icon';
import Button from 'components/react/Button';
import Loader from 'components/react/Loader';

import { fetchJSON } from '../../utils/fetch.js';

import reducer, { initialState, resetErrors } from './reducer';
import {
  setInputValueAction,
  resetAllValuesAction,
  putErrorsAction,
  setFormStateAction,
  setFormLoadingAction,
} from './actions';
import validate from './helpers/validate';

import COUNTRIES from '../../assets/data/countries.json';

import './styles.scss';

const SELECT_DEFAULT = 'Select…';

export default function FeedbackModalContainer({ onClose, csrfTokenName, csrfTokenValue }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { errors, loading, formState, inputs } = state;

  useEffect(() => {
    document.body.style.top = `-${window.scrollY}px`;
    document.body.classList.add('modal-active');
    return () => {
      const scrollY = document.body.style.top;
      document.body.classList.remove('modal-active');
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    };
  }, []);

  const resetFields = () => dispatch(resetAllValuesAction());

  const submitForm = async (node) => {
    dispatch(setFormLoadingAction(true));

    const errors = {
      firstName: !validate('text', inputs.firstName),
      lastName: !validate('text', inputs.lastName),
      fromEmail: !validate('email', inputs.fromEmail),
      message: !validate('textarea', inputs['message[body]']),
    };

    const valid = Object.keys(errors).every((key) => !errors[key]);

    if (valid) {
      dispatch(putErrorsAction(resetErrors()));
    } else {
      dispatch(putErrorsAction(errors));
      dispatch(setFormLoadingAction(false));
      return;
    }

    try {
      const data = new FormData(node);
      data.append('action', 'contact-form/send');
      if (inputs.firstName.length > 0 || inputs.lastName.length > 0) {
        data.append('fromName', `${inputs.firstName} ${inputs.lastName}`);
      }
      if (csrfTokenName && csrfTokenValue) {
        data.append(csrfTokenName, csrfTokenValue);
      }
      await fetchJSON('/', 'POST', data);
      dispatch(setFormLoadingAction(false));
      resetFields();
      dispatch(setFormStateAction('success', __('feedback-success')));
    } catch (err) {
      dispatch(setFormLoadingAction(false));
      if (err.data) {
        dispatch(putErrorsAction(err.data));
      } else {
        dispatch(setFormStateAction('error', __('feedback-failure')));
      }
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    submitForm(event.target);
  };

  const handleInputClearClick = (name) => dispatch(setInputValueAction(name, ''));

  const handleInput = (name, value) => dispatch(setInputValueAction(name, value));

  const handleSelect = (name, value) => dispatch(setInputValueAction(name, value));

  return (
    <section className="feedback-modal">
      <div className="feedback">
        <div className="feedback__header">
          <div className="feedback__icon">
            <Icon id="feedback-big" />
          </div>
          <header className="feedback__title">
            <h2>{__('feedback-title')}</h2>
          </header>
          <div className="feedback__header__intro">
            <div
              className="feedback__intro"
              dangerouslySetInnerHTML={{ __html: __('feedback-intro') }}
            />
          </div>
        </div>
        <div className="feedback__body">
          <div className="feedback__body__main">
            <div className="feedback__body__intro">
              <div
                className="feedback__intro"
                dangerouslySetInnerHTML={{ __html: __('feedback-intro') }}
              />
            </div>
            <form
              className="feedback__form"
              method="post"
              action=""
              onSubmit={onSubmit}
              disabled={loading || (formState.status && !loading)}
              noValidate
            >
              <TextInput
                name="firstName"
                placeholder="Type here"
                label={__('feedback-firstname')}
                required
                error={errors.firstName && __('feedback-firstname-error')}
                onInput={handleInput}
                onClickClear={handleInputClearClick}
                value={inputs.firstName}
              />
              <TextInput
                name="lastName"
                label={__('feedback-surname')}
                required
                error={errors.lastName && __('feedback-surname-error')}
                onInput={handleInput}
                onClickClear={handleInputClearClick}
                value={inputs.lastName}
              />
              <TextInput
                name="fromEmail"
                label={__('feedback-email')}
                required
                error={errors.fromEmail && __('feedback-email-error')}
                onInput={handleInput}
                onClickClear={handleInputClearClick}
                value={inputs.fromEmail}
              />
              <TextInput
                name="message[Organisation]"
                label={__('feedback-org')}
                onInput={handleInput}
                onClickClear={handleInputClearClick}
                value={inputs['message[Organisation]']}
              />
              <SelectInput
                name="message[Country][]"
                defaultOption={SELECT_DEFAULT}
                label={__('feedback-country')}
                options={COUNTRIES}
                onChange={handleSelect}
                value={inputs['message[Country][]']}
              />
              <SelectInput
                name="message[Dataset][]"
                defaultOption={SELECT_DEFAULT}
                label={__('feedback-dataset')}
                options={__('feedback-dataset-options')}
                onChange={handleSelect}
                value={inputs['message[Dataset][]']}
              />
              <TextareaInput
                name="message[body]"
                label={__('feedback-comment')}
                required
                error={errors.message && __('feedback-comment-error')}
                onInput={handleInput}
                value={inputs['message[body]']}
              />
              <div className="feedback__form__actions">
                <div>
                  <Button
                    label={__('feedback-submit')}
                    colour="primary"
                    size="large"
                    type="submit"
                  />
                  {onClose && (
                    <Button
                      label={__('feedback-cancel')}
                      colour="secondary"
                      size="large"
                      type="button"
                      onClick={onClose}
                    />
                  )}
                </div>
                <p>* Required field</p>
              </div>
            </form>
            {loading && (
              <div className="feedback__overlay">
                <div className="feedback__loading">
                  <Loader />
                </div>
              </div>
            )}
            {formState.status && !loading && (
              <div className="feedback__overlay">
                <div className="feedback__response">
                  {formState.status === 'success' ? <Icon id="success" /> : <Icon id="error" />}
                  <p>{formState.reply}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {onClose && (
        <button className="feedback__close" type="button" onClick={onClose}>
          <Icon id="close-modal" />
        </button>
      )}
    </section>
  );
}
