import React from 'react';
import clsx from 'clsx';

import './styles.scss';

import ErrorMessage from '../ErrorMessage';
import Icon from '../Icon';

export default function SelectInput({
  name,
  defaultOption,
  label,
  error,
  errorString,
  value,
  options,
  required,
  onChange,
}) {
  const handleChange = (e) => onChange(e.target.getAttribute('name'), e.target.value);

  return (
    <>
      <span className={clsx('select-input', error && 'select-input--error')}>
        <label htmlFor={name} className="select-input__label">
          {`${label}${required ? ' *' : ''}`}
        </label>
        <select
          id={name}
          name={name}
          className="select-input__field"
          value={value}
          error={error}
          required={required}
          onBlur={handleChange}
        >
          {defaultOption && (
            <option key="default" value="" disabled selected={!value}>
              {defaultOption}
            </option>
          )}
          {options
            ? options.map((item) => {
                const value = item.value ? item.value : item;
                return (
                  <option key={value} value={value}>
                    {item.label ? item.label : item}
                  </option>
                );
              })
            : null}
        </select>
        <span className="select-input__icon">
          <Icon id="chevron-right-small" />
        </span>
      </span>
      {error ? <ErrorMessage message={errorString} /> : null}
    </>
  );
}
