import React from 'react';

import './styles.scss';

import {
  GovernanceGroupRow,
  GovernanceParentRow,
  GovernanceChildRow,
  GovernanceHeader,
} from '../GovernanceRow';

export default function LegalityChart({ data, header }) {
  return (
    <table className="legality-chart">
      <GovernanceHeader
        title={header.title}
        labels={header.labels}
        max={4}
        binary={header.binary}
      />
      {data.map((datum) => {
        return (
          <>
            {datum.title ? (
              <GovernanceGroupRow key={datum.title} title={datum.title} max={4} />
            ) : null}
            {datum.children.map((child) => {
              if (child.comment) {
                return null;
              }

              return (
                <GovernanceParentRow
                  modifier="legality"
                  key={child.title}
                  title={child.title}
                  score={child.score}
                  max={4}
                  flagged={child.flagged}
                  comment={child.comment}
                >
                  {child.children.map((grandchild) => (
                    <GovernanceChildRow
                      modifier="legality"
                      key={grandchild.title}
                      title={grandchild.title}
                      score={grandchild.score}
                      max={4}
                      flagged={grandchild.flagged}
                    />
                  ))}
                </GovernanceParentRow>
              );
            })}
          </>
        );
      })}
    </table>
  );
}
