import React, { useState } from 'react';
import keyBy from 'lodash/keyBy';

import BarChart from 'components/react/BarChart';
import Legend from 'components/react/Legend';
import PortalTooltip from 'components/react/PortalTooltip';
import ChartReadout from 'components/react/ChartReadout';
import SourceLabel from 'components/react/SourceLabel';
import ChartHeader from 'components/react/ChartHeader';
import ChartContainer from 'components/react/ChartContainer';

import { groupAndMergeData } from '../../utils/data.js';
import { formatThousands } from '../../utils/formatters.js';

import './styles.scss';

function fillMissingData(data, key, keyDomain) {
  const keyedData = keyBy(data, key);
  const buffer = [];
  let prevData = null;
  let keyValue = keyDomain[0];
  while (keyValue <= keyDomain[1]) {
    if (keyedData[keyValue]) {
      buffer.push(keyedData[keyValue]);
      prevData = keyedData[keyValue];
    } else {
      buffer.push({ ...prevData, [key]: keyValue, estimate: true, estimateYear: prevData.year });
    }
    keyValue++;
  }
  return buffer;
}

export default function CertificationAreaContainer({ data, xDomain, source }) {
  const [activeX, setActiveX] = useState(2018);
  const [hovered, setHovered] = useState(false);

  const mergedData = groupAndMergeData(data, 'year', 'type', 'area');
  const filledData = fillMissingData(mergedData, 'year', xDomain);

  const datum = filledData.find((d) => d.year === activeX);
  const datumYear = datum.estimateYear || activeX;

  const tooltipData = {
    FSC: formatThousands(datum['FSC']),
    PEFC: formatThousands(datum['PEFC']),
    'FSC & PEFC (double certification)': formatThousands(
      datum['FSC & PEFC (double certification)']
    ),
  };

  return (
    <div className="certification-area-container">
      {/* FIXME: Trim column names in data? */}
      {/* FIXME: Remove lowercasing */}
      <ChartHeader
        data={formatThousands(datum['Total certified '])}
        year={datumYear}
        measure={__('country-use-certified-unit').toLowerCase()}
      />
      <ChartContainer variant="barchart">
        <BarChart
          data={filledData}
          xKey="year"
          xDomain={xDomain}
          yKeys={['FSC', 'PEFC', 'FSC & PEFC (double certification)']}
          estimateKey="estimate"
          activeX={activeX}
          hovered={hovered}
          onHover={(x, event) => {
            const active = event && (event.type === 'mouseenter' || event.type === 'touchstart');
            setActiveX(active ? x : 2018);
            setHovered(active);
          }}
        />
      </ChartContainer>
      <Legend
        items={[
          { id: 'FSC', title: 'FSC' },
          { id: 'PEFC', title: 'PEFC' },
          { id: 'FSC & PEFC (double certification)', title: 'FSC & PEFC' },
        ]}
      />
      {source && <SourceLabel html={source} />}
      {hovered && (
        <PortalTooltip>
          {datum.estimate ? (
            <ChartReadout
              title="No data available"
              body="Before 2015, FSC and PEFC data was only compiled every five years."
            />
          ) : (
            <ChartReadout
              title={`Certified forest area ${activeX}, ha`}
              data={tooltipData}
              keys={['FSC', 'PEFC', 'FSC & PEFC (double certification)']}
              labels={{
                FSC: 'FSC',
                PEFC: 'PEFC',
                'FSC & PEFC (double certification)': 'FSC & PEFC (double certification)',
              }}
              withSwatches
            />
          )}
        </PortalTooltip>
      )}
    </div>
  );
}
