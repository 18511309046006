let nodeMutationObserver;
if (MutationObserver) {
  nodeMutationObserver = new MutationObserver(handleNodeMutation);
}

export function parseComponents() {
  const nodes = document.querySelectorAll('[data-component]');
  const components = [];

  nodes.forEach((node) => {
    let props = {};
    try {
      props = JSON.parse(node.querySelector('script').textContent);
    } catch (err) {
      console.error(err);
    }

    if (nodeMutationObserver) {
      nodeMutationObserver.observe(node, { childList: true });
    } else {
      removePlaceholders(node);
    }

    components.push({
      node,
      id: node.dataset.component,
      props,
    });
  });

  return components;
}

// TODO: Detach observer when all components have loaded and rendered
export function handleNodeMutation(mutations) {
  mutations.forEach((mutation) => {
    if (
      mutation.addedNodes &&
      mutation.addedNodes.length &&
      mutation.addedNodes[0].nodeType !== Node.TEXT_NODE
    ) {
      removePlaceholders(mutation.target);
    }
  });
}

export function removePlaceholders(node) {
  const placeholder = node.querySelector('.loader, .placeholder');
  if (placeholder && placeholder.parentNode) {
    placeholder.parentNode.removeChild(placeholder);
  }
}

export function initBehaviours(behaviours) {
  const nodes = document.querySelectorAll('[data-behaviour]');
  nodes.forEach((node) => {
    const behaviour = node.getAttribute('data-behaviour');
    if (behaviours[behaviour]) {
      new behaviours[behaviour](node);
    }
  });
}

// TODO: Move to separate file
export function removeChildrenFromTab(element) {
  const links = element.getElementsByTagName('a');
  const buttons = element.getElementsByTagName('button');
  const inputs = element.getElementsByTagName('input');

  links.forEach((element) => element.setAttribute('tabindex', '-1'));
  buttons.forEach((element) => element.setAttribute('tabindex', '-1'));
  inputs.forEach((element) => element.setAttribute('tabindex', '-1'));
}

export function addChildrenToTab(element) {
  const links = element.getElementsByTagName('a');
  const buttons = element.getElementsByTagName('button');
  const inputs = element.getElementsByTagName('input');

  links.forEach((element) => element.removeAttribute('tabindex'));
  buttons.forEach((element) => element.removeAttribute('tabindex'));
  inputs.forEach((element) => element.removeAttribute('tabindex'));
}
