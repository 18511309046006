import fetch from 'unfetch';
import formDataEntries from 'form-data-entries';

export default class PublicationFilter {
  static id = 'publication-filter';

  constructor(node) {
    this.node = node;
    this.formNode = this.node.querySelector('[data-ref="filter-form"]');
    this.clearNodes = this.formNode.querySelectorAll('[data-ref="clear"]');
    this.filterInfoNode = this.formNode.querySelector('[data-ref="filter-info"]');
    this.filterInfoCountNode = this.filterInfoNode.querySelector('[data-ref="filter-info-count"]');

    this.node.addEventListener('click', this.handlePaginationClick);
    this.formNode.addEventListener('change', this.handleChange);
    this.clearNodes.forEach((node) => node.addEventListener('click', this.handleClearClick));
  }

  handleChange = (event) => {
    // TODO: The form is currently simple enough that the `.matches()` filter
    // below is not actually required, as there are no other elements that will
    // generate `change` events
    if (event.target && event.target.matches('input[type=checkbox]')) {
      this.update();
      this.loadContent();
    }
  };

  handleClearClick = () => {
    this.formNode.querySelectorAll('input[type=checkbox]').forEach((node) => {
      node.checked = false;
    });
    this.update();
    this.loadContent();
  };

  handlePaginationClick = (event) => {
    const node = event.target;
    if (node.closest('[data-ref="pagination"]')) {
      event.preventDefault();
      this.loadContent(node.href);
    }
  };

  loadContent = (url) => {
    if (!url) {
      const parameters = formDataEntries(this.formNode).map(
        (e) => encodeURIComponent(e[0]) + '=' + encodeURIComponent(e[1])
      );
      url = `?${parameters.join('&')}`;
    }
    history.pushState(null, null, url);
    this.node.classList.add('is-loading');
    this.scrollToTop();

    fetch(url)
      .then((r) => r.text())
      .then((html) => this.replaceContent(html));
  };

  replaceContent = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    document.getElementById('contents').innerHTML = doc.getElementById('contents').innerHTML;
    this.node.classList.remove('is-loading');
  };

  scrollToTop = () => {
    if ('scrollBehavior' in document.documentElement.style) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } else {
      window.scrollTo(0, 0);
    }
  };

  update = () => {
    const count = [...this.formNode.querySelectorAll('input[type=checkbox]')].reduce(
      (count, node) => count + (node.checked ? 1 : 0),
      0
    );
    if (count) {
      this.filterInfoNode.hidden = false;
      this.filterInfoCountNode.innerHTML = count;
      this.clearNodes.forEach((node) => (node.hidden = false));
    } else {
      this.filterInfoNode.hidden = true;
      this.clearNodes.forEach((node) => (node.hidden = true));
    }
  };
}
