import React from 'react';
import getRange from 'lodash/range';
import { scaleBand, scaleLinear } from 'd3-scale';
import { SizeMe } from 'react-sizeme';
import clsx from 'clsx';

import { eventMatchesPointerType } from '../../../utils/pointer.js';

import './styles.scss';

const PIXEL_OFFSET = 0.5;
const X_MARGIN = 3;
const MARGIN = {
  top: 2,
  // XXX: Plus half bounds stroke width, less half bar stroke width
  right: X_MARGIN + 0.5 - 0.5,
  bottom: 2,
  // XXX: Plus half bounds stroke width
  left: X_MARGIN + 0.5,
};
const BAR_GUTTER = 1.5;
const X_AXIS_HEIGHT = 8 * 2;
const BAR_STUB_HEIGHT = 1.5;

function getBandScale(domain, range, gutter) {
  const width = range[1] - range[0];

  if (domain.length > 2) {
    return scaleBand()
      .domain(domain)
      .rangeRound(range)
      .paddingInner(gutter / (width / (domain.length - 1)));
  }

  const domainExpanded = getRange(domain[0], domain[domain.length - 1] + 1);

  return scaleBand()
    .domain(domainExpanded)
    .range(range)
    .paddingInner(gutter / (width / domainExpanded.length));
}

export function BarChart({
  width,
  height,
  data,
  xKey,
  xDomain,
  yKeys,
  estimateKey,
  activeX,
  hovered,
  onHover,
  onHoverDeadZone,
  contiguousKeys = [],
  modifier,
}) {
  const bounds = {
    top: MARGIN.top,
    right: width - MARGIN.right,
    bottom: height - X_AXIS_HEIGHT - MARGIN.bottom,
    left: MARGIN.left,
  };

  const xScale = getBandScale(xDomain, [bounds.left, bounds.right], BAR_GUTTER);
  const xBandWidth = xScale.bandwidth();

  const totals = data.map((datum) => {
    return yKeys.reduce((total, key) => total + datum[key] || 0, 0);
  });
  const yMax = Math.max(...totals);
  const yScale = scaleLinear()
    .domain([0, yMax])
    .range([bounds.bottom, bounds.top]);

  let xMax = xDomain[0];

  const handleHoverStart = (datum) => (event) => {
    if (!eventMatchesPointerType(event)) {
      return;
    }

    onHover(datum, event);
  };

  const handleHoverEnd = (event) => {
    if (!eventMatchesPointerType(event)) {
      return;
    }

    onHover();
  };

  const bars = [];
  data.forEach((d) => {
    xMax = Math.max(d[xKey], xMax);

    const x = xScale(d[xKey]);
    let acc = 0;
    const segments = yKeys.map((key) => {
      const y0 = yScale(acc);
      acc += d[key] || 0;
      const y1 = yScale(acc);
      return (
        <>
          <polygon
            key={key}
            data-key={key}
            points={`${0},${y0} ${0},${y1} ${xBandWidth},${y1} ${xBandWidth},${y0}`}
          />
          {modifier === 'exports' && !contiguousKeys.includes(key) ? (
            <line x1={0} x2={xBandWidth} y1={y0} y2={y0} style={{ strokeWidth: '1px' }} />
          ) : null}
        </>
      );
    });
    const isEstimate = estimateKey && d[estimateKey];

    bars.push(
      <g
        className={clsx(
          'barchart__bar',
          { 'barchart__bar--active': activeX === d[xKey] },
          isEstimate && 'barchart__bar--estimate'
        )}
        transform={`translate(${x},0)`}
      >
        {segments}
        {acc === 0 && (
          <rect
            className="barchart__stub"
            x={0}
            y={bounds.bottom - BAR_STUB_HEIGHT}
            width={xBandWidth - 1}
            height={BAR_STUB_HEIGHT}
          />
        )}
        {modifier === 'exports' ? <line x1={0} x2={0} y1={0} y2={bounds.bottom} /> : null}
        <rect
          className="barchart__hitbox"
          x={-BAR_GUTTER}
          y={0}
          width={xBandWidth + BAR_GUTTER * 2}
          height={height}
          onMouseEnter={onHover && handleHoverStart(d[xKey])}
          onMouseLeave={onHover && handleHoverEnd}
          onTouchStart={onHover && handleHoverStart(d[xKey])}
          onTouchEnd={onHover && handleHoverEnd}
          onTouchCancel={onHover && handleHoverEnd}
        />
      </g>
    );
  });

  return (
    <svg
      className={clsx(
        'barchart__graphic',
        { 'barchart__graphic--hovered': hovered },
        modifier && `barchart__graphic--${modifier}`
      )}
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
    >
      <g className="barchart__bounds">
        <line x1={0 + PIXEL_OFFSET} y1={bounds.top} x2={0 + PIXEL_OFFSET} y2={height} />
        <line x1={width - PIXEL_OFFSET} y1={bounds.top} x2={width - PIXEL_OFFSET} y2={height} />
      </g>
      <g className="barchart__xaxis" transform={`translate(0 ${height - X_AXIS_HEIGHT})`}>
        <line x1={0} y1={-PIXEL_OFFSET} x2={width} y2={-PIXEL_OFFSET} />
        {xDomain.length === 2 ? (
          <>
            <text className="barchart__label barchart__label--start" x={bounds.left} dy="0.9em">
              {xDomain[0]}
            </text>
            <text className="barchart__label barchart__label--end" x={bounds.right} dy="0.9em">
              {xDomain[1]}
            </text>
          </>
        ) : (
          xDomain.map((x) => {
            return (
              <text
                className="barchart__label"
                x={xScale(x)}
                dx={xScale.bandwidth() / 2}
                dy="0.9em"
              >
                {x}
              </text>
            );
          })
        )}
      </g>
      <g className="barchart__bars">{bars}</g>
      {onHoverDeadZone && xMax < xDomain[1] && (
        <rect
          className="barchart__deadzone"
          x={xScale(xMax + 1)}
          width={width - xScale(xMax + 1)}
          y={0}
          height={height}
          onMouseEnter={(event) => {
            onHoverDeadZone(true, event);
          }}
          onMouseLeave={onHoverDeadZone}
          onTouchStart={(event) => {
            onHoverDeadZone(true, event);
          }}
          onTouchEnd={onHoverDeadZone}
          onTouchCancel={onHoverDeadZone}
        />
      )}
    </svg>
  );
}

export default function SizedBarChart(props) {
  return (
    <SizeMe monitorHeight>
      {({ size }) => (
        <div className="barchart">
          {size.width && size.height && (
            <div>
              <BarChart width={size.width} height={size.height} {...props} />
            </div>
          )}
        </div>
      )}
    </SizeMe>
  );
}
