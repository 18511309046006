import React, { useEffect, useState } from 'react';
import groupBy from 'lodash/groupBy';
import { scaleThreshold } from 'd3-scale';
import fetch from 'unfetch';

import GovernanceScoresChart from 'components/react/GovernanceScoresChart';
import RadioGroup from 'components/react/RadioGroup';
import Link from 'components/react/Link';
import SourceLabel from 'components/react/SourceLabel';

import { POLICY_SCORE_LABELS } from '../../constants.js';

import './styles.scss';

function parseDataToTree(data, scale) {
  const tree = [];
  data.forEach((datum) => {
    const [a, b] = datum.policy_id.toString().split('.');
    if (!b) {
      const parentId = a;
      datum.children = data
        .filter((d) => {
          const [a, b] = d.policy_id.toString().split('.');
          return b && a === parentId;
        })
        .map((d) => {
          const score = Math.round(d.score);
          return {
            ...d,
            originalScore: score,
            score: d.status ? null : scale(score),
            title: d.policy_name,
          };
        });
      const score = Math.round(datum.score);
      tree.push({
        ...datum,
        originalScore: score,
        score: datum.status ? null : scale(score),
        title: datum.policy_name,
      });
    }
  });
  return tree;
}

export default function GovernanceContainer({ dataUrl, source, methodologyLink, lastUpdated }) {
  const [data, setData] = useState(null);
  const [activeYear, setActiveYear] = useState(2018);

  useEffect(() => {
    fetch(dataUrl)
      .then((response) => response.json())
      .then((data) => {
        setData(data);
      });
  }, [dataUrl]);

  const scale = scaleThreshold()
    .domain([21, 41, 61, 81])
    .range([1, 2, 3, 4, 5]);

  let processedData;
  let yearData;
  let overallData;
  if (data) {
    const dataByYear = groupBy(data, 'year');
    const temp = {};
    Object.keys(dataByYear).forEach((year) => {
      temp[year] = parseDataToTree(dataByYear[year], scale);
    });
    // console.log(dataByYear);
    // console.log(temp);
    processedData = temp;
    yearData = processedData[activeYear].filter((datum) => datum.policy_id !== 0);
    overallData = processedData[activeYear].find((datum) => datum.policy_id === 0);
  }

  if (!yearData) {
    return null;
  }

  return (
    <div className="governance-container">
      <div className="governance-container__main">
        <GovernanceScoresChart
          data={yearData}
          header={{
            title: 'Indicators',
            labels: POLICY_SCORE_LABELS,
          }}
          overall={overallData}
          statuses={{
            'no data': 'No data',
            'coming soon': 'Coming soon…',
          }}
        />
      </div>
      <div className="governance-container__controls">
        <form>
          <RadioGroup
            checkedItem={activeYear}
            options={[
              { label: '2008', value: 2008 },
              { label: '2013', value: 2013 },
              { label: '2018', value: 2018 },
            ]}
            group="policy-assessment-options"
            onChange={(value) => {
              setActiveYear(parseInt(value, 10));
            }}
          />
        </form>
        {methodologyLink && (
          <Link
            type="methodology"
            label={methodologyLink.title}
            tag="a"
            url={methodologyLink.url}
          />
        )}
        {lastUpdated && (
          <div className="governance-container__activity">
            <p>{lastUpdated}</p>
          </div>
        )}
      </div>
      <div className="governance-container__footer">{source && <SourceLabel html={source} />}</div>
    </div>
  );
}
