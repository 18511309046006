import React from 'react';

import ImageSlider from 'components/react/ImageSlider';
import Legend from 'components/react/Legend';
import SourceLabel from 'components/react/SourceLabel';
import ChartContainer from 'components/react/ChartContainer';

import './styles.scss';

export default function MapContainer({ images, source }) {
  return (
    <div className="map-container">
      <div className="map-container__main">
        <ChartContainer variant="image-slider">
          <ImageSlider images={images} marginX="8" />
        </ChartContainer>
      </div>
      <div className="map-container__footer">
        <Legend
          items={[
            { id: 'cover', title: 'Tree cover' },
            { id: 'gain', title: 'Tree cover gain' },
            { id: 'loss', title: 'Tree cover loss' },
          ]}
        />
        <SourceLabel html={source} />
      </div>
    </div>
  );
}
