import sortBy from 'lodash/sortBy';
import arrayRemove from 'lodash/remove';
import groupBy from 'lodash/groupBy';

export function aggregateTrailingData(data, sortKey, limit, exclusionCondition) {
  const filteredData = data.filter((datum) => datum[sortKey] > 0);
  const sortedData = sortBy(filteredData, sortKey).reverse();
  const excludedData = exclusionCondition ? arrayRemove(sortedData, exclusionCondition) : [];
  const topSlice = sortedData.slice(0, limit);
  const aggregateSlice = sortedData.slice(limit).concat(excludedData);
  /* eslint-disable no-param-reassign */
  const aggregateData = aggregateSlice.reduce((result, datum, i) => {
    const illegalVolume = datum.volume * datum.illegal_pc;
    if (i === 0) {
      result = { ...datum, ...exclusionCondition, illegalVolume: illegalVolume };
    } else {
      result.volume += datum.volume;
      result.value += datum.value;
      result.illegalVolume += illegalVolume;
      result.illegal_pc = result.illegalVolume / result.volume;
    }
    return result;
  }, null);
  /* eslint-enable no-param-reassign */
  if (aggregateData) {
    topSlice.push(aggregateData);
  }
  return topSlice;
}

export function sliceLeadingData(data, sortKey, limit) {
  const filteredData = data.filter((datum) => datum[sortKey] > 0);
  const sortedData = sortBy(filteredData, sortKey).reverse();
  return sortedData.slice(0, limit);
}

export function groupAndMergeData(data, groupingKey, propKey, valueKey) {
  const groupedData = groupBy(data, groupingKey);
  const mergedData = Object.keys(groupedData).map((groupKey) => {
    // Grouping the data converts the group keys to strings, so we pick
    // the original key from the first data item
    const originalGroupKey = groupedData[groupKey][0][groupingKey];
    const buffer = { [groupingKey]: originalGroupKey };
    groupedData[originalGroupKey].forEach((datum) => {
      buffer[datum[propKey]] = datum[valueKey];
    });
    return buffer;
  });
  return mergedData;
}
