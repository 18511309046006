import React, { lazy, Suspense } from 'react';

// XXX: Webpack will split the lazy loaded components styles into a separate file
// along with the Javascript. This prevents us from using those styles to create
// loading placeholders. So, to workaround that we import the required styles here.
import './styles.scss';
import 'components/react/HomepageMap/styles.scss';
import 'components/react/ScrollCarousel/styles.scss';

const HomepageMapsContainer = lazy(() => import('./index.js'));

export default function LazyHomepageMapsContainer(props) {
  return (
    <Suspense>
      <HomepageMapsContainer {...props} />
    </Suspense>
  );
}
