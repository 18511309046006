import React, { useEffect, useState } from 'react';
import groupBy from 'lodash/groupBy';
import fetch from 'unfetch';

import LegalityChart from 'components/react/LegalityChart';
import RadioGroup from 'components/react/RadioGroup';
import Link from 'components/react/Link';
import Legend from 'components/react/Legend';

import { illegalitySections } from 'components/react/GovernanceScoresChart/constants.js';

import './styles.scss';

const OVERALL_CATEGORY = 'Overall likelihood of illegality';
const VALUE_SCORE_MAP = {
  G: 1,
  Y: 2,
  O: 3,
  R: 4,
  H: 4,
};
const FLAGGED_VALUE = 'H';

function formatData(data, comments = {}) {
  const groupedData = groupBy(data, 'group');

  Object.keys(groupedData).forEach((key) => {
    groupedData[key] = groupBy(groupedData[key], 'type');
  });

  const finalData = [];
  let hasFlaggedData = false;

  Object.keys(groupedData).forEach((group) => {
    const groupChildren = [];
    const groupDatum = { title: group !== 'null' ? group : null, children: groupChildren };

    Object.keys(groupedData[group]).forEach((type) => {
      const typeChildren = [];
      const typeDatum = { title: type, children: typeChildren };

      groupedData[group][type].forEach((datum) => {
        const score = VALUE_SCORE_MAP[datum.value] || null;
        const flagged = datum.value === FLAGGED_VALUE;

        if (flagged) {
          hasFlaggedData = true;
        }

        if (datum.category === OVERALL_CATEGORY) {
          typeDatum.score = score;
          typeDatum.flagged = flagged;
          typeDatum.comment = comments[datum.value];
        } else {
          typeChildren.push({ title: datum.category, score, flagged });
        }
      });

      groupChildren.push(typeDatum);
    });

    finalData.push(groupDatum);
  });

  return { data: finalData, flagged: hasFlaggedData };
}

export default function LegalityContainer({
  dataUrl,
  dataUrls,
  comments,
  source,
  methodologyLink,
}) {
  const [data, setData] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeYear, setActiveYear] = useState(2018);

  useEffect(() => {
    if (dataUrls && dataUrls.length) {
      Promise.all(dataUrls.map((x) => fetch(x.url).then((response) => response.json()))).then(
        (data) => {
          setData(data);
        }
      );
    } else {
      fetch(dataUrl)
        .then((response) => response.json())
        .then((data) => {
          setData(data);
        });
    }
  }, [dataUrl, dataUrls]);

  if (!data) {
    return null;
  }

  const activeData = dataUrls && dataUrls.length ? data[activeIndex] : data;

  let chartData;
  let flagged;
  if (data) {
    const activeYearData = activeData.filter((d) => d.year === activeYear);
    const formattedData = formatData(activeYearData, comments);
    chartData = formattedData.data;
    flagged = formattedData.flagged;
  }

  if (!chartData) {
    return null;
  }

  return (
    <div className="legality-container">
      {dataUrls && dataUrls.length ? (
        <div className="legality-container__header">
          <form>
            <RadioGroup
              checkedItem={activeIndex}
              options={dataUrls.map((x, i) => ({ label: x.title, value: i }))}
              group="legality-index"
              onChange={(value) => {
                setActiveIndex(value);
              }}
            />
          </form>
        </div>
      ) : null}
      <div className="legality-container__main">
        <LegalityChart
          data={chartData}
          header={{
            title: 'Type',
            labels: illegalitySections,
            binary: true,
          }}
        />
      </div>
      <div className="legality-container__footer">
        <form className="legality-container__controls">
          <RadioGroup
            checkedItem={activeYear}
            options={[
              { label: '2000', value: 2000 },
              { label: '2008', value: 2008 },
              { label: '2013', value: 2013 },
              { label: '2018', value: 2018 },
            ]}
            group="legality-years"
            onChange={(value) => {
              setActiveYear(parseInt(value, 10));
            }}
          />
        </form>
        <div className="legality-container__annotations">
          {flagged ? (
            <div className="legality-container__legend">
              <Legend items={[{ id: 'flagged', title: __('footnote-legality-contentious') }]} />
            </div>
          ) : null}
          {methodologyLink && (
            <Link
              type="methodology"
              label={methodologyLink.title}
              tag="a"
              url={methodologyLink.url}
            />
          )}
        </div>
      </div>
    </div>
  );
}
