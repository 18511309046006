import React, { useState, useEffect } from 'react';
import fetch from 'unfetch';

import SankeyChart from 'components/react/SankeyChart';
import RangeInput from 'components/react/RangeInput';
import RadioGroup from 'components/react/RadioGroup';
import PortalTooltip from 'components/react/PortalTooltip';
import ChartReadout from 'components/react/ChartReadout';
import LegendScale from 'components/react/LegendScale';
import ChartContainer from 'components/react/ChartContainer';

import { aggregateTrailingData, sliceLeadingData } from '../../utils/data.js';
import { formatVolume, formatValue, formatPercentage } from '../../utils/formatters.js';

import './styles.scss';

const LABEL_KEYS = {
  exporters: 'exporter_title',
  products: 'product',
};

export default function ImportsContainer({
  dataUrl,
  label,
  extent = [2000, 2014],
  maxYear = 2018,
}) {
  const [data, setData] = useState(null);
  const [activeYear, setActiveYear] = useState(extent[1]);
  const [activeOption, setActionOption] = useState('exporters');
  const [activeFlow, setActiveFlow] = useState(null);
  const [deadZoneHovered, setDeadZoneHovered] = useState(null);

  useEffect(() => {
    fetch(dataUrl)
      .then((response) => response.json())
      .then((data) => {
        setData(data);
      });
  }, [dataUrl]);

  let processedData;
  if (data) {
    const yearData = data[activeOption].filter((datum) => datum.year === activeYear);
    processedData =
      activeOption === 'exporters'
        ? aggregateTrailingData(yearData, 'volume', 5, { exporter_iso: 'ROW' })
        : sliceLeadingData(yearData, 'volume', 6);
    processedData = processedData.map((datum) => {
      return {
        ...datum,
        exporter_title: __ENV__.countryTitles[datum.exporter_iso],
      };
    });
  }

  let formattedTooltipData;
  let tooltipTitle;
  if (data && activeFlow !== null) {
    const tooltipData = processedData[activeFlow];
    formattedTooltipData = {
      volume: formatVolume(tooltipData.volume),
      value: formatValue(tooltipData.value),
      illegal_pc: formatPercentage(tooltipData.illegal_pc),
    };
    tooltipTitle =
      activeOption === 'exporters'
        ? `${__ENV__.countryTitles[tooltipData.exporter_iso]} to ${label} timber imports`
        : `${label} ${tooltipData.product.toLowerCase()} imports`;
  }

  function onChangeYearInput(e) {
    setActiveYear(parseInt(e.currentTarget.value, 10));
  }

  return (
    <div className="imports-container">
      <form className="imports-container__header">
        <RadioGroup
          checkedItem={activeOption}
          options={[
            { label: 'By source', value: 'exporters' },
            { label: 'By product', value: 'products' },
          ]}
          group="imports-options"
          onChange={(value) => {
            setActionOption(value);
          }}
        />
      </form>
      <div className="imports-container__main">
        <ChartContainer variant="sankey">
          {data && (
            <SankeyChart
              data={processedData}
              label={label}
              direction="to"
              numFlows={6}
              dataKey="volume"
              colourKey="illegal_pc"
              renderLabel={(datum, pc) => (
                <>
                  <tspan>{datum[LABEL_KEYS[activeOption]]}</tspan>
                  <tspan> {`${(pc * 100).toFixed(0)}%`}</tspan>
                </>
              )}
              activeFlow={activeFlow}
              onHover={(datum) => {
                setActiveFlow(datum);
              }}
            />
          )}
        </ChartContainer>
        <div className="imports-container__legend">
          <LegendScale
            title="Estimated proportion of illegal trade"
            labelStart="Low"
            labelEnd="High"
            items={[
              { id: 'low' },
              { id: 'med-low' },
              { id: 'med' },
              { id: 'med-high' },
              { id: 'high' },
            ]}
          />
        </div>
      </div>
      <div className="imports-container__footer">
        <RangeInput
          min={extent[0]}
          max={extent[1]}
          trackMax={maxYear}
          value={activeYear}
          formattedValue={activeYear}
          onInput={onChangeYearInput}
          onChange={onChangeYearInput}
          onHoverDeadZone={(active) => {
            setDeadZoneHovered(active);
          }}
        />
      </div>
      {formattedTooltipData && (
        <PortalTooltip>
          <ChartReadout
            title={tooltipTitle}
            data={formattedTooltipData}
            keys={['volume', 'value', 'illegal_pc']}
            labels={{
              volume: 'Volume',
              value: 'Value',
              illegal_pc: 'Est. illegal trade',
            }}
          />
        </PortalTooltip>
      )}
      {deadZoneHovered && (
        <PortalTooltip variant="narrow">
          <ChartReadout body={__('country-trade-forthcoming')} />
        </PortalTooltip>
      )}
    </div>
  );
}
