const MESSAGE_NODE_ID = 'msg-external-link';
const LINK_SELECTOR = 'a[href^="http"]:not([target])';

export default class ExtermalLinkTargets {
  static id = 'external-link-targets';

  constructor(node) {
    if (!('MutationObserver' in window)) {
      return;
    }

    const messageNode = document.createElement('p');
    messageNode.setAttribute('hidden', true);
    messageNode.id = MESSAGE_NODE_ID;
    messageNode.innerHTML = 'Opens an external site in a new window';
    node.appendChild(messageNode);

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type !== 'childList') {
          return;
        }

        document.querySelectorAll(LINK_SELECTOR).forEach(this.setLinkTarget);
      });
    });

    observer.observe(node, {
      childList: true,
      subtree: true,
    });

    document.querySelectorAll(LINK_SELECTOR).forEach(this.setLinkTarget);
  }

  setLinkTarget(node) {
    // XXX: SVG href properties are not strings
    if (typeof node.href !== 'string') {
      return;
    }

    if (node.href.indexOf(window.location.host) !== -1) {
      return;
    }

    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener');
    node.setAttribute('aria-describedby', MESSAGE_NODE_ID);
  }
}
