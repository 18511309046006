import React from 'react';
import clsx from 'clsx';

import ErrorMessage from '../ErrorMessage';

import './styles.scss';

export default function TextareaInput({
  required,
  name,
  placeholder,
  label,
  value,
  error,
  onInput,
}) {
  const handleInput = (e) => onInput(e.target.getAttribute('name'), e.target.value);

  return (
    <span className={clsx('textarea-input', error && 'textarea-input--error')}>
      <label htmlFor={name} className="textarea-input__label">
        {`${label}${required ? ' *' : ''}`}
        <textarea
          id={name}
          name={name}
          className="textarea-input__field"
          placeholder={placeholder}
          onInput={handleInput}
          required={required}
          error={error}
          value={value}
        />
      </label>
      {error ? <ErrorMessage message={error} /> : null}
    </span>
  );
}
