import { addChildrenToTab, removeChildrenFromTab } from '../../../utils/components.js';

function getLocationHashTarget() {
  const hash = window.location.hash.slice(1);
  return document.querySelector(`[name="${hash}"]`);
}

export default class DisclosurePanelBehaviour {
  static id = 'disclosure-panel';

  constructor(node) {
    this.node = node;
    this.triggerNode = node.querySelector('[data-ref="disclosure-trigger"]');
    this.contentNode = node.querySelector('[data-ref="disclosure-content"]');

    if (node.dataset.type === 'footnotes') {
      const targetNode = getLocationHashTarget();
      if (this.contentNode.contains(targetNode)) {
        this.animateOpen();
      }

      window.addEventListener('hashchange', this.handleHashChange);
    }

    this.triggerNode.addEventListener('click', this.handleClickTrigger);
  }

  handleClickTrigger = () => {
    const open = this.node.getAttribute('aria-expanded') === 'true';
    if (open) {
      this.animateClose();
    } else {
      this.animateOpen();
    }
  };

  handleHashChange = () => {
    const targetNode = getLocationHashTarget();
    if (this.contentNode.contains(targetNode)) {
      this.animateOpen();
    }
  };

  animateClose = () => {
    requestAnimationFrame(() => {
      const contentHeight = this.contentNode.scrollHeight;
      this.contentNode.style.height = `${contentHeight}px`;
      requestAnimationFrame(() => {
        this.contentNode.style.height = '0px';
      });
    });

    this.node.setAttribute('aria-expanded', 'false');
    removeChildrenFromTab(this.contentNode);
  };

  animateOpen = () => {
    const open = this.node.getAttribute('aria-expanded') === 'true';

    if (open) {
      return;
    }

    const contentHeight = this.contentNode.scrollHeight;
    this.contentNode.style.height = `${contentHeight}px`;

    this.node.setAttribute('aria-expanded', 'true');

    this.contentNode.addEventListener('transitionend', this.handleTransEnd);
    addChildrenToTab(this.contentNode);
  };

  handleTransEnd = () => {
    this.contentNode.style.height = 'auto';
    this.contentNode.removeEventListener('transitionend', this.handleTransEnd);
  };
}
