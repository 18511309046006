const sections = {
  '0': 'failing',
  '1': 'weak',
  '2': 'fair',
  '3': 'good',
  '4': 'very good',
};

const illegalitySections = ['Low', 'Low to medium', 'Medium to high', 'High'];

export { sections, illegalitySections };
