// Require all Twig component behaviours
const exportObj = {};

const req = require.context('./', true, /behaviours\/.+\.js$|behaviour\.js$/);
req.keys().forEach((key) => {
  const behaviour = req(key).default;
  if (!behaviour.id) {
    throw new Error(`Behaviour ${behaviour.name} is missing id`);
  }
  exportObj[behaviour.id] = behaviour;
});

export default exportObj;
