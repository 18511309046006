/* eslint-disable no-undef */
import React from 'react';
import { createPortal } from 'react-dom';

import TimberConsumptionContainer from './containers/TimberConsumption';
import CertificationAreaContainer from './containers/CertificationArea';
import ExportsContainer from './containers/Exports';
import LossContainer from './containers/Loss';
import ExportsVolumeContainer from './containers/ExportsVolume';
import ImportsContainer from './containers/Imports';
import ImportsVolumeContainer from './containers/ImportsVolume';
import EmissionsContainer from './containers/Emissions';
import LandAreaContainer from './containers/LandArea';
import MapContainer from './containers/Map';
import GovernanceContainer from './containers/Governance';
import FeedbackModalContainer from './containers/FeedbackModal';
import HomepageMapsContainer from './containers/HomepageMaps/lazy.js';
import LegalityContainer from './containers/Legality';

import BarChart from './components/react/BarChart';

import behaviours from './components/twig/behaviours.js';
import { parseComponents, initBehaviours } from './utils/components.js';
import bridge from './utils/bridge.js';

const COMPONENTS = {
  BarChart: BarChart,
  TimberConsumptionContainer: TimberConsumptionContainer,
  CertificationAreaContainer: CertificationAreaContainer,
  ExportsContainer: ExportsContainer,
  LossContainer: LossContainer,
  ExportsVolumeContainer: ExportsVolumeContainer,
  ImportsContainer: ImportsContainer,
  ImportsVolumeContainer: ImportsVolumeContainer,
  EmissionsContainer: EmissionsContainer,
  LandAreaContainer: LandAreaContainer,
  MapContainer: MapContainer,
  GovernanceContainer: GovernanceContainer,
  HomepageMapsContainer: HomepageMapsContainer,
  FeedbackModalContainer: FeedbackModalContainer,
  LegalityContainer: LegalityContainer,
};

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      components: [],
      showFeedbackModal: false,
    };
  }

  componentDidMount() {
    initBehaviours(behaviours);

    if (process.env.NODE_ENV === 'development') {
      window.setTimeout(() => {
        const components = parseComponents();
        this.setState({ components });
      }, 2000);
    } else {
      const components = parseComponents();
      this.setState({ components });
    }

    bridge.on('feedback:open', () => {
      this.setState({ showFeedbackModal: true });
    });
  }

  render() {
    return (
      <>
        {this.state.components.map((component) => {
          const Constructor = COMPONENTS[component.id];
          return createPortal(<Constructor {...component.props} />, component.node);
        })}
        {this.state.showFeedbackModal && (
          <FeedbackModalContainer
            csrfTokenName={__ENV__.csrfTokenName}
            csrfTokenValue={__ENV__.csrfTokenValue}
            onClose={() => this.setState({ showFeedbackModal: false })}
          />
        )}
      </>
    );
  }
}
