import { format } from 'd3-format';

import { FORMAT_EMPTY_PLACEHOLDER } from '../constants.js';

const MILLIONS_SUFFIX = 'M';
const SCALE_SUFFIXES = ['', 'k', 'M'];
const PRECISION = 2;

export const formatThousands = format(',');

function isEmpty(n) {
  return n === null || n === undefined || Number.isNaN(n);
}

export function formatPercentage(value, precision = 0) {
  return `${(value * 100).toFixed(precision)}%`;
}

export function formatPrecision(value, precision = 2) {
  return parseFloat(value.toFixed(precision));
}

export function formatMillions(value, precision) {
  return `${formatPrecision(value, precision)}${MILLIONS_SUFFIX}`;
}

export function formatScaleSuffix(value) {
  if (isEmpty(value)) {
    return FORMAT_EMPTY_PLACEHOLDER;
  }

  const tier = (Math.log10(value) / 3) | 0;

  if (tier === 0) {
    return value.toFixed(PRECISION);
  }

  const scale = Math.pow(10, tier * 3);

  return `${(value / scale).toFixed(PRECISION)}${SCALE_SUFFIXES[tier]}`;
}

export function formatVolume(volume, plain) {
  if (isEmpty(volume)) {
    return FORMAT_EMPTY_PLACEHOLDER;
  }

  // All volume data is million RWE m³
  const thousandsFormattingThreshold = 100000 / 1000000;
  const formattedValue =
    Math.abs(volume) < thousandsFormattingThreshold
      ? formatThousands(formatPrecision((volume * 1000000) / 1000, 1) * 1000)
      : formatMillions(volume, 1);

  if (plain) {
    return formattedValue;
  }

  return `RWE ${formattedValue} m³`;
}

// All value data is million USD
export function formatValue(value, plain) {
  if (isEmpty(value)) {
    return FORMAT_EMPTY_PLACEHOLDER;
  }

  if (plain) {
    return formatMillions(value);
  }

  return `USD ${formatMillions(value)}`;
}
