import React from 'react';

import clsx from 'clsx';

import Icon from '../Icon';

export default function Link({
  tag = 'a',
  type = 'internal-link',
  url = null,
  label,
  ...otherProps
}) {
  const Tag = tag;
  const iconId = {
    'external-link': 'external-link-small',
    download: 'download',
    methodology: 'methodology',
    feedback: 'feedback',
    'internal-link': 'chevron-right-small',
  };

  // FIXME: Why don't null props get omitted when rendering here?
  const optionalProps = {};
  if (url) {
    optionalProps.href = url;
  }
  if (type === 'download' && url) {
    // Work around Preact issue with download boolean attribute
    // https://github.com/preactjs/preact/issues/2673
    const filename = url.substring(url.lastIndexOf('/') + 1);
    optionalProps.download = filename || true;
  }

  return (
    <Tag className="link" {...optionalProps} {...otherProps}>
      <span
        className={clsx(
          'link__icon',
          (type === 'internal-link' || type === 'external-link') && 'link__icon--with-bg'
        )}
      >
        <Icon id={iconId[type]} />
      </span>
      <span className="link__text">
        <span className="link__text__main">{label}</span>
      </span>
    </Tag>
  );
}
