import React, { useState } from 'react';

import { GovernanceBar } from '../GovernanceBar';
import Icon from '../Icon';

import './styles.scss';

export function GovernanceParentRow({
  title,
  score,
  max,
  status,
  modifier,
  flagged,
  comment,
  children,
}) {
  const [expanded, setExpanded] = useState(false);

  const accordionToggle = () => {
    setExpanded(!expanded);
  };

  const disabled = !!comment;

  return (
    <tbody
      className={`
        governance-chart-row__body--accordion
        ${expanded ? 'governance-chart-row__accordion-active' : ''}
        ${modifier ? `governance-chart-row__body--${modifier}` : ''}
      `}
      data-disabled={disabled}
    >
      <tr onClick={!disabled ? accordionToggle : null} className="governance-chart-row">
        <td className="governance-chart-row__icon-container">
          <button
            type="button"
            className="governance-chart-row__icon"
            aria-label="indicator-toggle"
            disabled={disabled}
          >
            <Icon id="chevron-right-medium" />
          </button>
        </td>
        <td className="governance-chart-row__label">{title}</td>
        <td className="governance-chart-row__bar">
          {status || comment ? (
            <span className="governance-chart-row__status">{status || comment}</span>
          ) : (
            <GovernanceBar
              height="tall"
              score={score}
              max={max}
              flagged={flagged}
              modifier={modifier}
            />
          )}
        </td>
      </tr>
      <tr className="governance-chart-row__mobile-bar" aria-hidden="true">
        <td />
        <td>
          {status || comment ? (
            <span className="governance-chart-row__status governance-chart-row--tall">
              {status || comment}
            </span>
          ) : (
            <GovernanceBar
              height="tall"
              score={score}
              max={max}
              flagged={flagged}
              modifier={modifier}
            />
          )}
        </td>
        <td />
      </tr>
      {children}
    </tbody>
  );
}

export function GovernanceChildRow({ title, score, max, status, flagged, modifier }) {
  return (
    <>
      <tr className="governance-chart-row__sub-row">
        <td className="governance-chart-row__indent"></td>
        <td className="governance-chart-row__label">{title}</td>
        <td className="governance-chart-row__bar">
          {status ? (
            <span className="governance-chart-row__status">{status}</span>
          ) : (
            <GovernanceBar
              height="thin"
              score={score}
              max={max}
              flagged={flagged}
              modifier={modifier}
            />
          )}
        </td>
      </tr>
      <tr className="governance-chart-row__mobile-bar" aria-hidden="true">
        <td />
        <td>
          {status ? (
            <span className="governance-chart-row__status governance-chart-row--thin">
              {status}
            </span>
          ) : (
            <GovernanceBar
              height="thin"
              score={score}
              max={max}
              flagged={flagged}
              modifier={modifier}
            />
          )}
        </td>
        <td />
      </tr>
    </>
  );
}

export function GovernanceHeader({ title, labels, max, binary }) {
  return (
    <thead>
      <tr
        className={`governance-chart-row__header-row ${
          binary ? 'governance-chart-row__header-row--binary' : ''
        }`}
        data-max={max}
      >
        <th className="governance-chart-row__header-indent"></th>
        <th className="governance-chart-row__header-title">{title}</th>
        <th className="governance-chart-row__header-labels">
          {labels
            ? labels.map((label) => (
                <span className="governance-chart-row__header-label">{label}</span>
              ))
            : null}
        </th>
      </tr>
    </thead>
  );
}

export function GovernanceOverallRow({ title, score, status }) {
  return (
    <tbody className="governance-chart-row__body--overall">
      <tr className={`governance-chart-row`}>
        <td className="governance-chart-row__icon-container" />
        <td className="governance-chart-row__label">{title}</td>
        <td className="governance-chart-row__bar">
          {status ? (
            <span className="governance-chart-row__status">{status}</span>
          ) : (
            <GovernanceBar height="tall" score={score} />
          )}
        </td>
      </tr>
      <tr className="governance-chart-row__mobile-bar">
        <td />
        <td>
          {status ? (
            <span className="governance-chart-row__status">{status}</span>
          ) : (
            <GovernanceBar height="tall" score={score} />
          )}
        </td>
        <td />
      </tr>
    </tbody>
  );
}

export function GovernanceGroupRow({ title }) {
  return (
    <tbody className="governance-chart-row__body--group">
      <tr className={`governance-chart-row`}>
        <td className="governance-chart-row__icon-container" />
        <td className="governance-chart-row__label">{title}</td>
        <td className="governance-chart-row__bar" />
      </tr>
      <tr className="governance-chart-row__mobile-bar">
        <td />
        <td />
        <td />
      </tr>
    </tbody>
  );
}
