import {
  SET_INPUT_VALUE,
  RESET_ALL_VALUES,
  PUT_ERRORS,
  SET_FORM_LOADING,
  SET_FORM_STATE,
} from './constants';

export const setInputValueAction = (inputName, value) => ({
  type: SET_INPUT_VALUE,
  value,
  inputName,
});

export const resetAllValuesAction = () => ({
  type: RESET_ALL_VALUES,
});

export const putErrorsAction = (errors) => ({
  type: PUT_ERRORS,
  errors,
});

export const setFormStateAction = (status, message) => ({
  type: SET_FORM_STATE,
  formStatus: status,
  formMessage: message,
});

export const setFormLoadingAction = (bool) => ({
  type: SET_FORM_LOADING,
  bool,
});
