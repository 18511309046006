import {
  SET_INPUT_VALUE,
  RESET_ALL_VALUES,
  PUT_ERRORS,
  SET_FORM_LOADING,
  SET_FORM_STATE,
} from './constants';

const resetInputValues = () => ({
  firstName: '',
  lastName: '',
  fromEmail: '',
  'message[Organisation]': '',
  'message[Country][]': '',
  'message[Dataset][]': '',
  'message[body]': '',
});

export const resetErrors = () => ({
  firstName: null,
  lastName: null,
  fromEmail: null,
  message: null,
});

export const initialState = {
  errors: resetErrors(),
  loading: false,
  formState: {
    status: null,
    reply: null,
  },
  inputs: resetInputValues(),
};

export default function reducer(state, action) {
  switch (action.type) {
    case SET_INPUT_VALUE:
      return {
        ...state,
        inputs: {
          ...state.inputs,
          [action.inputName]: action.value,
        },
      };
    case RESET_ALL_VALUES:
      return { ...state, inputs: resetInputValues() };
    case PUT_ERRORS:
      return { ...state, errors: action.errors };
    case SET_FORM_LOADING:
      return { ...state, loading: action.bool };
    case SET_FORM_STATE:
      return {
        ...state,
        formState: {
          status: action.formStatus,
          reply: action.formMessage,
        },
      };
    default:
      return state;
  }
}
