import React from 'react';

import Icon from '../Icon';

import './styles.scss';

export default function ErrorMessage({ message }) {
  return (
    <span className="error-message">
      <span className="error-message__icon">
        <Icon id="error" />
      </span>
      {message}
    </span>
  );
}
